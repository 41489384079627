/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useEffect, useMemo } from 'react';
import { isString, isUndefined } from 'lodash-es';
import { useField, useFormikContext } from 'formik';
import { Input, PasswordField, InputProps } from '../form-elements';

export type FormikInputProps = Omit<InputProps, 'onChange'> & {
  name: string;
  onChangeCb?: (
    value: Parameters<NonNullable<InputProps['onChange']>>[0] | string
  ) => void;
};

const FormikInput = ({ name, onChangeCb, ...props }: FormikInputProps) => {
  const { setFieldValue } = useFormikContext();
  const [{ value, onChange, ...field }, { touched, error }] = useField(name);

  const Component = useMemo(
    () => (props.type === 'password' ? PasswordField : Input),
    [props.type]
  );

  const renderValue = useMemo(
    () => (props.select && (value === 0 || isUndefined(value)) ? '' : value),
    [props.select, value]
  );

  useEffect(() => {
    if (!renderValue) {
      onChangeCb?.('');
    }
  }, [renderValue, onChangeCb]);

  return (
    <Component
      {...field}
      value={renderValue}
      onChange={(newValue) => {
        isString(newValue) ? setFieldValue(name, newValue) : onChange(newValue);
        onChangeCb?.(newValue);
      }}
      {...props}
      {...(touched && !!error
        ? {
            error: true,
            helperText: error,
          }
        : {})}
    />
  );
};

export { FormikInput };
