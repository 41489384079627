/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../components/alert-modals/Icon';
import { Text as DefText } from '../../components/alert-modals/Text';
import { Title } from '../../components/alert-modals/Title';
import { Button as DefButton } from '../../components/buttons';
import { AuthLayout } from '../../layouts';
import { paths } from '../paths';

const RebalanceLoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper style={{ position: 'relative' }}>
      <Icon type={'successBlue'} />
      <Title>{t('REBALANCE_LOGIN_PAGE__title')}</Title>
      <Text>{t('REBALANCE_LOGIN_PAGE__desc1')}</Text>
      <Text>{t('REBALANCE_LOGIN_PAGE__desc2')}</Text>
      <Button variant={'contained'} onClick={() => navigate(paths.login)}>
        {t('REBALANCE_LOGIN_PAGE__button')}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled(AuthLayout)`
  padding-left: 96px;
  padding-right: 96px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 34px;
    padding-right: 34px;
  }
`;

const Text = styled(DefText)`
  margin-left: 0;
  margin-right: 0;
`;

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 20px;
`;

export { RebalanceLoginPage };
