/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useState, useEffect, ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { omit } from 'lodash-es';
import { Trans, useTranslation } from 'react-i18next';
import { SiteData, externalLinkProps } from '../../utils/consts';
import { getDownMedia, getColor } from '../../styles';
import { Button as DefButton, ButtonProps } from '../buttons';
import { CheckboxWithLabel as DefCheckbox } from '../form-elements';
import {
  ContentModalWrapper,
  ModalContentWithButton,
  ModalContentWithButtonProps,
} from '../modal-elements';
import { Title as DefTitle, Text as DefText, Link } from '../texts';

export type TrialCreditCardModalProps = Pick<
  ComponentProps<typeof ContentModalWrapper>,
  'opened' | 'animation'
> & {
  type: 'in-trial' | 'trial-ended';
  buttonProps: ButtonProps;
} & ModalContentWithButtonProps;

const TrialCreditCardModal = ({
  opened,
  animation,
  type,
  buttonProps,
  ...props
}: TrialCreditCardModalProps) => {
  const { t } = useTranslation();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    termsAccepted && setShowError(false);
  }, [termsAccepted]);

  const title =
    type === 'in-trial'
      ? 'TRIAL_CREDIT_CARD_MODAL__inTrialTitle'
      : 'TRIAL_CREDIT_CARD_MODAL__trialEndedTitle';

  return (
    <Wrapper opened={opened} animation={animation}>
      <ContentWrapper {...props}>
        <Title>{t(title)}</Title>
        <DesktopColumn>
          <Section>
            <Subtitle>{t('TRIAL_CREDIT_CARD_MODAL__subtitle1')}</Subtitle>
            <Text>{t('TRIAL_CREDIT_CARD_MODAL__1text1')}</Text>
            <Text>
              <ul>
                <Trans
                  i18nKey={'TRIAL_CREDIT_CARD_MODAL__1text2'}
                  components={[<li />]}
                />
              </ul>
            </Text>
            <Text>{t('TRIAL_CREDIT_CARD_MODAL__1text3')}</Text>
          </Section>
          <Section>
            <Subtitle>{t('TRIAL_CREDIT_CARD_MODAL__subtitle2')}</Subtitle>
            <Text>{t('TRIAL_CREDIT_CARD_MODAL__2text1')}</Text>
          </Section>
        </DesktopColumn>
        <DesktopColumn>
          <Section $withTopMobileMargin>
            <Subtitle>{t('TRIAL_CREDIT_CARD_MODAL__subTitle3')}</Subtitle>
            <Text>{t('TRIAL_CREDIT_CARD_MODAL__3text1')}</Text>
          </Section>
          <Section>
            <Subtitle>{t('TRIAL_CREDIT_CARD_MODAL__subTitle4')}</Subtitle>
            <Text>{t('TRIAL_CREDIT_CARD_MODAL__4text1')}</Text>
            <Checkbox
              style={{
                marginTop: 0,
                marginBottom: 30,
              }}
              name={'accepted-conditions'}
              value={termsAccepted}
              label={
                <>
                  {t('TRIAL_CREDIT_CARD_MODAL__4checkbox1text')}{' '}
                  <Link href={SiteData.TERMS_LINK} {...externalLinkProps}>
                    {t('TRIAL_CREDIT_CARD_MODAL__4checkbox1link')}
                  </Link>{' '}
                  {t('TRIAL_CREDIT_CARD_MODAL__4checkbox2text')}{' '}
                  <Link href={SiteData.PRIVACY_LINK} {...externalLinkProps}>
                    {t('TRIAL_CREDIT_CARD_MODAL__4checkbox2link')}
                  </Link>{' '}
                  {t('TRIAL_CREDIT_CARD_MODAL__4checkbox3text')}
                </>
              }
              error={showError}
              helperText={
                showError
                  ? t('TRIAL_CREDIT_CARD_MODAL__4checkboxErrorText')
                  : undefined
              }
              onChange={(_, checked) => setTermsAccepted(checked)}
            />
            <Button
              {...omit(buttonProps, 'onClick')}
              onClick={(event) => {
                if (termsAccepted) {
                  setShowError(false);
                  buttonProps.onClick?.(event);
                } else {
                  setShowError(true);
                }
              }}
            >
              {t('TRIAL_CREDIT_CARD_MODAL__4buttonText')}
            </Button>
          </Section>
        </DesktopColumn>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(ContentModalWrapper)`
  ${getDownMedia('md')} {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  ${getDownMedia('sm')} {
    padding-top: 30px;
    padding-bottom: 10px;
  }
`;

const ContentWrapper = styled(ModalContentWithButton)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
  max-width: 1020px;
  margin: auto;

  ${getDownMedia('lg')} {
    width: 100%;
    grid-template-columns: 1fr;
  }

  ${getDownMedia('md')} {
    width: 90%;
    max-width: none;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  ${getDownMedia('sm')} {
    width: 100%;
    margin-bottom: 0;
  }
`;

const Title = styled(DefTitle).attrs({
  withLine: true,
})`
  grid-column: 1 / -1;
  margin-bottom: 20px;
  color: ${getColor('veniceBlue')};

  ${({ theme: { breakpoints } }) => breakpoints.between(960, 1000)} {
    font-size: 1.6rem;
  }
`;

const DesktopColumn = styled.div`
  ${getDownMedia('lg')} {
    display: contents;
  }
`;

const Section = styled.section<{
  $withTopMobileMargin?: boolean;
}>`
  ${({ $withTopMobileMargin = false }) =>
    $withTopMobileMargin &&
    css`
      ${getDownMedia('lg')} {
        margin-top: 20px;
      }
    `};

  & + & {
    margin-top: 20px;
  }
`;

export const Subtitle = styled(DefText).attrs({
  component: 'h2',
  variant: 'h4',
})`
  color: ${getColor('veniceBlue')};
`;

export const Text = styled(DefText)`
  & + & {
    margin-top: 20px;
  }

  ul {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;

    li {
      padding-left: 12px;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.palette.primary.main};
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
  }
`;

// noinspection CssUnusedSymbol
const Checkbox = styled(DefCheckbox)`
  margin-top: 24px;
  margin-bottom: 10px;

  .MuiFormControlLabel-label {
    font-size: 0.9rem;
  }

  .MuiFormHelperText-root {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const Button = styled(DefButton)`
  width: 100%;
`;

export { TrialCreditCardModal };
