/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { TableCell } from './TableCell';

export const OrderCell = styled(TableCell)`
  width: 38px;
  padding: 12px 6px 12px 12px;
`;
