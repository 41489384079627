/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from './Icon';
import { Text } from './Text';
import { Title } from './Title';

const KrakenExchangeLocked = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon type={'sadFace'} />
      <Title>{t('NOTIFICATION_EXCHANGE_LOCKED__krakenTitle')}</Title>
      <Text>{t('NOTIFICATION_EXCHANGE_LOCKED__krakenDesc')}</Text>
    </>
  );
};

export { KrakenExchangeLocked };
