/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

const GearIcon = (props: IconProps) => (
  <Icon viewBox="0 0 19.653 19.727" {...props}>
    <g fill="currentColor">
      <path d="M7.427 19.5h-.1c-.9-.2-1.8-.6-2.6-1.1-.2-.1-.3-.4-.2-.7.2-.2.3-.6.3-.9 0-1.1-.9-2-2-2-.3 0-.7.1-1 .2-.2.1-.5 0-.7-.2-.5-.8-.8-1.7-1.1-2.6-.1-.3.1-.5.3-.6 1-.2 1.5-.9 1.5-1.8s-.5-1.6-1.4-1.9c-.2-.1-.4-.3-.3-.6.2-.9.6-1.8 1.1-2.6.1-.2.4-.3.6-.1.3.1.7.2 1 .2 1.1 0 2-.9 2-2 0-.3-.1-.7-.2-1-.1-.2 0-.5.2-.7.8-.5 1.7-.8 2.6-1.1.2 0 .4.2.5.4.3.9 1 1.4 1.9 1.4s1.6-.5 1.9-1.4c.1-.2.3-.4.6-.3.9.2 1.8.6 2.6 1.1.2.1.3.4.2.7-.2.2-.3.6-.3.9 0 1.1.9 2 2 2 .3 0 .7-.1 1-.2.2-.1.5 0 .7.2.5.8.8 1.7 1.1 2.6.1.3-.1.5-.3.6-1 .2-1.5.9-1.5 1.8s.5 1.6 1.4 1.9c.2.1.4.3.3.6-.2.9-.6 1.8-1.1 2.6-.1.2-.4.3-.7.2-.3-.2-.6-.2-1-.2-1.1 0-2 .9-2 2 0 .3.1.7.2 1 .1.2 0 .5-.2.7-.8.5-1.7.8-2.6 1.1-.3.1-.5-.1-.6-.3-.3-.8-1-1.4-1.9-1.4s-1.6.5-1.9 1.4c.1 0-.1.1-.3.1zm2.4-2.7c1.1 0 2.1.6 2.7 1.6l1.5-.6c-.1-.3-.2-.6-.2-1 0-1.7 1.3-3 3-3 .3 0 .7.1 1 .2.3-.5.5-1 .6-1.5-1-.5-1.6-1.5-1.6-2.7s.6-2.1 1.6-2.7l-.6-1.5c-.3.1-.7.2-1 .2-1.7 0-3-1.3-3-3 0-.3.1-.7.2-1-.5-.3-1-.5-1.5-.6-.6 1-1.6 1.6-2.7 1.6s-2.1-.6-2.7-1.6l-1.5.6c.1.3.2.7.2 1 0 1.7-1.3 3-3 3-.3 0-.7-.1-1-.2-.3.5-.5 1-.6 1.5 1 .6 1.6 1.6 1.6 2.7s-.6 2.1-1.6 2.7l.6 1.5c.3-.1.7-.2 1-.2 1.7 0 3 1.3 3 3 0 .3-.1.7-.2 1 .5.3 1 .5 1.5.6.6-1 1.6-1.6 2.7-1.6z" />
      <path d="M9.827 12.8c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zm0-5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  </Icon>
);

export { GearIcon };
