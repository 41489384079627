/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useState, useMemo, useEffect, useRef, ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import {
  isString,
  isNumber,
  isEmpty,
  isNil,
  toString,
  toNumber,
  debounce,
} from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { Formik, Form as DefForm, FormikConfig, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { sendSentryError } from 'utils/sentry';
import * as Yup from 'yup';
import { useApolloClient } from '@apollo/client';
import { InputAdornment, MenuItem as DefMenuItem } from '@mui/material';
import { getPrice } from '../../utils/currency';
import {
  PortfolioPartitionAction,
  CurrencyPercentageAmountDocument,
  CurrencyPercentageAmountQuery,
  CurrencyPercentageAmountQueryVariables,
  CurrencyPercentageAmountQueryResult,
} from 'apollo';
import { getUpMedia, getColor } from '../../styles';
import { useExchange, useAlert } from '../../providers';
import { Spinner } from '../../styled';
import { Button as DefButton } from '../buttons';
import { Input } from '../form-elements';
import {
  FormikInput as DefFormikInput,
  FormikInputProps,
} from '../formik-elements';
import { Text } from '../texts';

export type PortfolioManipulationFormProps = {
  portfolioManipulationAction: PortfolioPartitionAction;
  disabled?: boolean;
  options: Array<PortfolioManipulationFormCurrenciesOption>;
  inputsPrefix: string;
  buttonText: string;
  inputsAlwaysOneRowOnDesktop?: boolean;
  onSubmit: FormikConfig<FormValues>['onSubmit'];
} & ComponentProps<typeof Form>;

const PortfolioManipulationForm = ({
  disabled: disabledProp,
  portfolioManipulationAction,
  options,
  inputsPrefix,
  buttonText,
  inputsAlwaysOneRowOnDesktop = false,
  onSubmit,
  ...props
}: PortfolioManipulationFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const client = useApolloClient();

  const { exchange } = useExchange();

  const formikRef = useRef<FormikProps<FormValues> | null>(null);

  const [currency, setCurrency] = useState<
    Nullish<{
      id: string;
      symbol: string;
      redLabel: boolean;
    }>
  >(null);
  const [percent, setPercent] = useState('');
  const [resultCost, setResultCost] = useState(0);

  const {
    loading: loadingResultCost,
    error: errorOnResultCostLoad,
    value: resultCostResponse,
  } = useAsync(async () => {
    if (!exchange?.exchangeID) {
      return Promise.reject(
        new Error(`Data is wrong to calculate percentage cost`)
      );
    }

    if (!(currency?.id && percent)) {
      return Promise.resolve(null);
    }

    return client.query<
      CurrencyPercentageAmountQuery,
      CurrencyPercentageAmountQueryVariables
    >({
      query: CurrencyPercentageAmountDocument,
      fetchPolicy: 'no-cache',
      variables: {
        partitionAction: portfolioManipulationAction,
        exchangeId: toNumber(exchange.exchangeID),
        currencyId: toNumber(currency.id),
        percent: toNumber(percent),
      },
    });
  }, [portfolioManipulationAction, client, currency?.id, percent]);

  const schema = useMemo(() => getSchema(t), [t]);

  const debouncedSetPercentage = useMemo(
    () =>
      debounce((value: InputValue) => {
        setPercent(getInputValue(value));
      }, 500),
    []
  );

  const resultCostToRender = useMemo(() => {
    if (errorOnResultCostLoad) {
      return 'Error';
    }
    switch (portfolioManipulationAction) {
      case PortfolioPartitionAction.Starttrial:
      case PortfolioPartitionAction.Deposit:
        return getPrice(resultCost);
      case PortfolioPartitionAction.Withdraw: {
        const resultValue = getPrice(resultCost);
        return resultCost > 0 ? '≈ ' + resultValue : resultValue;
      }
    }
  }, [portfolioManipulationAction, errorOnResultCostLoad, resultCost]);

  useEffect(() => {
    let newValue = 0;

    const responseValue = (
      resultCostResponse as CurrencyPercentageAmountQueryResult | null
    )?.data?.getPortfolioCurrencyPercentageAmount.amount;

    if (responseValue) {
      newValue = responseValue;
    }

    setResultCost(newValue);
  }, [resultCostResponse]);

  useEffect(() => {
    if (errorOnResultCostLoad) {
      sendSentryError(errorOnResultCostLoad);
    }
  }, [errorOnResultCostLoad]);

  useEffect(() => {
    if (
      currency?.id &&
      portfolioManipulationAction !== PortfolioPartitionAction.Withdraw
    ) {
      formikRef.current?.setFieldValue('percent', '100');
      setPercent('100');
    }
  }, [portfolioManipulationAction, currency?.id]);

  /*
   * In case of starting trial and picking
   * up currency which amount in euro
   * less than 2500 show notification*/
  useEffect(() => {
    if (
      currency?.id &&
      portfolioManipulationAction === PortfolioPartitionAction.Starttrial &&
      currency.redLabel
    ) {
      setAlert({
        type: 'error',
        message: t(
          'PORTFOLIO_MANIPULATION_FORM__minCurrencyAmountStartTrialErrorText'
        ),
      });
    }
  }, [currency, portfolioManipulationAction, setAlert, t]);

  const onCurrencyChange = (id: Maybe<string>) => {
    if (!id) {
      setCurrency(null);
      return;
    }
    const currency = options.find(({ value }) => value === id);
    setCurrency({
      id,
      symbol: currency?.label || '',
      redLabel: !!currency?.redLabel,
    });
  };

  const disabled = disabledProp === true || isEmpty(options);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        currencyId: '',
        percent: '',
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ dirty, isSubmitting, isValid }) => (
        <Form
          $formType={portfolioManipulationAction}
          $inputsAlwaysOneRowOnDesktop={inputsAlwaysOneRowOnDesktop}
          {...props}
        >
          {(() => {
            const selectInput = (
              <SelectInput
                key={'selectInput'}
                id={`${inputsPrefix}-form-currency-id`}
                name={'currencyId'}
                type={'number'}
                select
                disabled={disabled}
                label={t('PORTFOLIO_MANIPULATION_FORM__currencyPlaceholder')}
                fullWidth={false}
                margin={'none'}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                onChangeCb={(value) => onCurrencyChange(getInputValue(value))}
              >
                {options.map(({ label, value, icon, cost, redLabel }) => (
                  <DefMenuItem key={value} value={value}>
                    {!!icon && <SelectIconImg src={icon} />}
                    <CurrencyLabel $red={!!redLabel}>
                      {label.toUpperCase()}
                    </CurrencyLabel>
                    {!isNil(cost) && (
                      <CurrencyCost $red={!!redLabel}>
                        {getPrice(cost)}
                      </CurrencyCost>
                    )}
                  </DefMenuItem>
                ))}
              </SelectInput>
            );
            const percentInput = (
              <TextInput
                key={'percentInput'}
                id={`${inputsPrefix}-form-percent`}
                name={'percent'}
                type={'number'}
                disabled={disabled}
                label={'%'}
                fullWidth={false}
                inputProps={{ min: 0, max: 100 }}
                margin={'none'}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                onChangeCb={debouncedSetPercentage}
              />
            );
            if (portfolioManipulationAction === 'WITHDRAW') {
              return (
                <>
                  {percentInput}
                  {selectInput}
                </>
              );
            } else {
              return (
                <>
                  {selectInput}
                  {percentInput}
                </>
              );
            }
          })()}
          <ResultInput
            $inputsAlwaysOneRowOnDesktop={inputsAlwaysOneRowOnDesktop}
            disabled
            margin={'none'}
            InputProps={{
              startAdornment: loadingResultCost ? (
                <InputAdornment position="start">
                  <Spinner size={20} />
                </InputAdornment>
              ) : null,
            }}
            value={resultCostToRender}
          />
          <Button
            type={'submit'}
            loading={isSubmitting}
            disabled={disabled || !(dirty && isValid)}
            buttonStyles={css`
              height: 100%;
            `}
          >
            {t(buttonText)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

type FormValues = {
  currencyId: string;
  percent: string;
};

export type PortfolioManipulationFormCurrenciesOption = {
  label: string;
  value: string;
  icon: string;
  cost?: string;
  redLabel?: boolean;
};

const resultBreakpoints = {
  tablet: 500,
  desktop: 1500,
} as const;

const Form = styled(DefForm)<{
  $formType: PortfolioPartitionAction;
  $inputsAlwaysOneRowOnDesktop: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ $formType }) =>
    $formType === PortfolioPartitionAction.Withdraw
      ? '2fr 4fr 3.4fr'
      : '4fr 2fr 3.4fr'};
  grid-gap: 15px;

  ${getUpMedia(resultBreakpoints.tablet)} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${({ $inputsAlwaysOneRowOnDesktop, $formType }) =>
    !$inputsAlwaysOneRowOnDesktop &&
    css`
      ${getUpMedia('lg')} {
        grid-template-columns: repeat(2, 1fr);
      }

      ${getUpMedia(resultBreakpoints.desktop)} {
        grid-template-columns: ${$formType === PortfolioPartitionAction.Withdraw
            ? '2fr 4fr 3fr'
            : '4fr 2fr 3fr'};
      }
    `};

  ${getUpMedia(1600)} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

// noinspection CssUnusedSymbol
const FormikInput = styled(DefFormikInput)`
  //flex: 0 0 calc(48%);

  .MuiInputLabel-root.Mui-disabled {
    display: none;
  }
`;

const TextInput = styled(FormikInput)`
  input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
  }
`;

const CurrencyListItemText = styled(Text)<{ $red: boolean }>`
  ${({ $red }) =>
    $red &&
    css`
      color: ${getColor('lightRed')};
    `}
`;

const CurrencyLabel = styled(CurrencyListItemText)`
  margin-right: 10px;
`;

const CurrencyCost = styled(CurrencyListItemText)`
  margin-left: auto;
`;

const SelectInput = styled(FormikInput)`
  /*noinspection CssUnusedSymbol*/
  .MuiSelect-select {
    display: flex;
    align-items: center;

    ${CurrencyCost} {
      display: none;
    }
  }
`;

const SelectIconImg = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 10px;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
`;

const ResultInput = styled(Input)<{
  $inputsAlwaysOneRowOnDesktop: boolean;
}>`
  ${({ $inputsAlwaysOneRowOnDesktop }) =>
    !$inputsAlwaysOneRowOnDesktop &&
    css`
      ${getUpMedia('lg')} {
        grid-column: 1 / -1;
      }
    `};

  ${getUpMedia(resultBreakpoints.desktop)} {
    grid-column: auto;
  }

  /*noinspection CssUnusedSymbol*/
  .MuiInputBase-input {
    text-align: right;
  }
`;

const Button = styled(DefButton)`
  width: 100%;
  grid-column: 1 / -1;
` as typeof DefButton;

type InputValue = Parameters<NonNullable<FormikInputProps['onChangeCb']>>[0];
const getInputValue = (value: InputValue) =>
  isString(value)
    ? value
    : isNumber(value)
    ? toString(value)
    : value.target.value;

// @ts-ignore
const getSchema = (t: TFunction): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    currencyId: Yup.string().required(t('COMMON_FORMS_ERROR__required')),
    percent: Yup.string()
      .test({
        name: 'max-value-test',
        test: (value) => !value || parseInt(value) <= 100,
        message: t('PORTFOLIO_MANIPULATION_FORM__maxValueError'),
      })
      .required(t('COMMON_FORMS_ERROR__required')),
  });

export { PortfolioManipulationForm };
