/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useCallback, useEffect, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isEmpty, toNumber, uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { ApolloError, useApolloClient } from '@apollo/client';
import { Link } from '@mui/material';
import {
  SiteData,
  externalLinkProps,
  serverResponseErrorsCodes,
} from '../../utils/consts';
import { getPrice } from '../../utils/currency';
import { getCryptoIconPath } from '../../utils/portfolio';
import { sendSentryError } from '../../utils/sentry';
import { isServerErrorOfType } from '../../utils/server-helpers';
import { useAlertModal, useIsMobile } from '../../hooks';
import {
  ExchangeCode,
  StartTrialStatus,
  PortfolioPartitionAction,
  ExchangesSubscriptionInitiatedDatesDocument,
  useStartSubscriptionMutation,
  StartSubscriptionCurrenciesOptionsDocument,
  StartSubscriptionCurrenciesOptionsQuery,
  StartSubscriptionCurrenciesOptionsQueryVariables,
} from '../../apollo';
import { getColor } from '../../styles';
import { useAlert, useExchangeWizard, useExchange } from '../../providers';
import { Spinner } from '../../styled';
import {
  PortfolioManipulationForm as DefPortfolioManipulationForm,
  PortfolioManipulationFormProps,
} from '../forms';
import { Text as DefText } from '../texts';

export type DepositStepProps = HTMLAttributes<HTMLDivElement>;

const ExchangeWizardDepositStep = (props: DepositStepProps) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { setAlert } = useAlert();
  const { exchange } = useExchange();
  const { moneyAmount, moneyExist } = useExchangeWizard();
  const { dispatch: dispatchAlertModal } = useAlertModal();

  const mobile = useIsMobile();

  const {
    loading: loadingOptions,
    error: errorOnOptionsLoad,
    value: options,
  } = useAsync(async () => {
    if (!exchange?.exchangeID) {
      throw new Error(
        'No exchange id in StartSubscriptionCurrenciesOptionsQuery'
      );
    }

    const { data } = await client.query<
      StartSubscriptionCurrenciesOptionsQuery,
      StartSubscriptionCurrenciesOptionsQueryVariables
    >({
      query: StartSubscriptionCurrenciesOptionsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        exchangeId: toNumber(exchange?.exchangeID),
      },
    });

    if (!data.getIndexPortfolio) {
      return [];
    }

    const options = await Promise.all(
      data.getIndexPortfolio.map(
        async ({
          currency: { id, symbol },
          cost,
          enoughToStartSubscription,
        }) => ({
          label: symbol,
          value: id,
          icon: await getCryptoIconPath(symbol),
          redLabel: !enoughToStartSubscription,
          cost,
        })
      )
    );

    return uniqBy([...options], 'value');
  }, [client, exchange?.exchangeID]);

  const showStartTrialError = useCallback(
    (error: ApolloError | Error) => {
      sendSentryError(error);
      const message = t(
        isServerErrorOfType({
          error,
          type: serverResponseErrorsCodes.START_SUBSCRIPTION_NOT_ENOUGH_MONEY,
        })
          ? ''
          : 'WIZARD_STEPPER__depositStepStartTrialErrorText'
      );
      setAlert({
        type: 'error',
        message,
      });
    },
    [t, setAlert]
  );

  const [startSubscription] = useStartSubscriptionMutation({
    refetchQueries: [
      {
        query: ExchangesSubscriptionInitiatedDatesDocument,
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      const status = data?.startSubscription;

      if (status === StartTrialStatus.Ok) {
        dispatchAlertModal([
          'setModalContent',
          'initialPortfolioRebalanceStart',
        ]);
      } else {
        showStartTrialError(new Error(`Start subscription status: ${status}`));
      }
    },
    onError: showStartTrialError,
  });

  useEffect(() => {
    if (errorOnOptionsLoad) {
      sendSentryError(errorOnOptionsLoad);
    }

    if (isEmpty(options)) {
      sendSentryError(
        new Error('There are no options in response for starting subscription')
      );
    }
  }, [errorOnOptionsLoad, options]);

  if (!exchange) return null;

  const onSubmit: PortfolioManipulationFormProps['onSubmit'] = async (
    values
  ) => {
    if (!exchange?.exchangeID) {
      sendSentryError(
        new Error('No exchange id in start subscription mutation')
      );
      setAlert({
        type: 'error',
        message: t('COMMON_ERROR'),
      });
      return;
    }

    await startSubscription({
      variables: {
        exchangeId: toNumber(exchange.exchangeID),
        currencyId: toNumber(values.currencyId),
        currencyPercent: toNumber(values.percent),
      },
    });
  };

  return (
    <Wrapper {...props}>
      <SaldoTitle variant={'h2'} component={'h3'} align={'center'}>
        {t('WIZARD_STEPPER__depositStepSaldoText')}:{' '}
        <span>{getPrice(moneyAmount ?? 0)}</span>
      </SaldoTitle>
      {!moneyExist ? (
        <>
          <Text>
            {t(descTexts[exchange.exchangeCode][mobile ? 'mobile' : 'desktop'])}
          </Text>
          <CenterLink
            href={linkTexts[exchange.exchangeCode].href}
            {...externalLinkProps}
          >
            {t(linkTexts[exchange.exchangeCode].text)}
          </CenterLink>
        </>
      ) : (
        <>
          <FormDescText>
            {t('WIZARD_STEPPER__depositStepSuccessText')}
          </FormDescText>
          {(() => {
            if (loadingOptions) {
              return (
                <Spinner
                  style={{
                    display: 'block',
                    margin: '20px auto',
                  }}
                />
              );
            }

            if (errorOnOptionsLoad || isEmpty(options)) {
              return (
                <Text weight={'bold'}>
                  {t(
                    'WIZARD_STEPPER__depositStepCurrenciesOptionsLoadErrorText'
                  )}
                </Text>
              );
            }

            return (
              <PortfolioManipulationForm
                disabled={!!exchange.subscriptionInitiatedDate}
                portfolioManipulationAction={
                  PortfolioPartitionAction.Starttrial
                }
                options={options}
                inputsPrefix={'deposit'}
                buttonText={'WIZARD_STEPPER__depositStepSuccessButton'}
                inputsAlwaysOneRowOnDesktop
                onSubmit={onSubmit}
              />
            );
          })()}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const SaldoTitle = styled(DefText)`
  margin-bottom: 30px;

  span {
    color: ${getColor('blue2')};
  }
`;

const Text = styled(DefText)`
  padding-left: 10px;
  padding-right: 10px;
  margin: 10px 0 20px;
  text-align: center;
  white-space: pre-line;
`;

const FormDescText = styled(Text)`
  align-self: center;
  max-width: 338px;
  padding: 0;
  margin: 0;
`;

const CenterLink = styled(Link)`
  display: block;
  text-align: center;
`;

const PortfolioManipulationForm = styled(DefPortfolioManipulationForm)`
  margin-top: 36px;
`;

const descTexts: {
  [key in ExchangeCode]: {
    [key2 in 'mobile' | 'desktop']: string;
  };
} = {
  [ExchangeCode.Kraken]: {
    mobile: 'WIZARD_STEPPER__krakenDepositStepContentDescMobile',
    desktop: 'WIZARD_STEPPER__krakenDepositStepContentDescDesktop',
  },
  [ExchangeCode.Bitvavo]: {
    mobile: 'WIZARD_STEPPER__bitvavoDepositStepContentDescMobile',
    desktop: 'WIZARD_STEPPER__bitvavoDepositStepContentDescDesktop',
  },
};

const linkTexts: Readonly<{
  [key in ExchangeCode]: {
    href: string;
    text: string;
  };
}> = {
  [ExchangeCode.Kraken]: {
    href: SiteData.KRAKEN_ADD_MONEY_LINK,
    text: 'WIZARD_STEPPER__krakenDepositStepContentLink',
  },
  [ExchangeCode.Bitvavo]: {
    href: SiteData.BITVAVO_ADD_MONEY_LINK,
    text: 'WIZARD_STEPPER__bitvavoDepositStepContentLink',
  },
};

export { ExchangeWizardDepositStep };
