/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { createRoot } from 'react-dom/client';
import { withSentry } from './utils/consts';
import './utils/countries';
import './utils/i18n';
import { initSentry } from './utils/sentry';
import { App } from './components';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

withSentry && initSentry();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// eslint-disable-next-line import/namespace
// serviceWorkerRegistration[developmentMode ? 'unregister' : 'register']();
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
