/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useCallback } from 'react';
import { isString } from 'lodash-es';
import { useDesktopSmall } from './useDesktopSmall';
import { useIsExactTablet } from './useIsExactTablet';
import { useIsMobile } from './useIsMobile';

export const useTruncateText = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsExactTablet();
  const isDesktopSmall = useDesktopSmall();

  return useCallback(
    (text?: string, customConfig?: Partial<Config>) => {
      if (!isString(text)) return '';

      const config = {
        ...defConfig,
        ...customConfig,
      };

      let strLength;

      switch (true) {
        case isMobile:
          strLength = config.mobile?.strLength;
          break;
        case isTablet:
          strLength = config.tablet?.strLength;
          break;
        case isDesktopSmall:
          strLength = config['desktop-small']?.strLength;
          break;
        default:
      }

      return text.substring(0, strLength);
    },
    [isMobile, isTablet, isDesktopSmall]
  );
};

type Config = {
  [key in 'mobile' | 'tablet' | 'desktop-small']: {
    strLength: number;
  };
};

const defConfig: Config = {
  mobile: {
    strLength: 10,
  },
  tablet: {
    strLength: 18,
  },
  'desktop-small': {
    strLength: 14,
  },
};
