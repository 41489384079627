/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

const PieOutlinedIcon = (props: IconProps) => (
  <Icon viewBox="0 0 18.9 19" {...props}>
    <g fill="currentColor">
      <path d="M8.5 19c-2.3 0-4.4-.9-6-2.5S0 12.8 0 10.5s.9-4.4 2.5-6S6.2 2 8.5 2c.3 0 .5.2.5.5V10h7.5c.3 0 .5.2.5.5 0 2.3-.9 4.4-2.5 6s-3.7 2.5-6 2.5zM8 3c-3.9.3-7 3.5-7 7.5C1 14.6 4.4 18 8.5 18c4 0 7.2-3.1 7.5-7H8.5c-.3 0-.5-.2-.5-.5z" />
      <path d="M18.4 9h-8c-.3 0-.5-.2-.5-.5v-8c0-.3.2-.5.5-.5 2.3 0 4.4.9 6 2.5s2.5 3.7 2.5 6c0 .3-.2.5-.5.5zm-7.5-1h7c-.2-3.7-3.2-6.7-7-7z" />
    </g>
  </Icon>
);

export { PieOutlinedIcon };
