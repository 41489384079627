/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { ExchangeCode } from '../apollo';
import bitvavoLogoIcon from '../assets/images/bitvavo-logo-icon.svg';
import krakenLogoIcon from '../assets/images/kraken-logo-icon.svg';

export const developmentMode = process.env.NODE_ENV !== 'production';

export const productionMode = !developmentMode;

export const withServiceWorker = productionMode && 'serviceWorker' in navigator;

export const withSentry =
  !developmentMode && !!process.env.REACT_APP_SENTRY_RELEASE;

export const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

// PHONE = '+31 (0)20 239 6159',

export enum SiteData {
  EMAIL = 'contact@mercuryredstone.com',
  ROOT_LINK = 'https://mercuryredstone.com',
  // KRAKEN_CREATE_ACCOUNT = 'https://r.kraken.com/Vyb93O',
  // KRAKEN_ABOUT = 'https://mercuryredstone.com/beveiliging',
  KRAKEN_LEARN_MORE = 'https://mercuryredstone.com/index-portefeuille-kraken/',
  KRAKEN_SIGNUP = 'https://www.kraken.com/nl/sign-up',
  KRAKEN_ADD_MONEY_LINK = 'https://support.kraken.com/hc/en-us/articles/360049073651-How-do-I-deposit-my-local-currency-to-Kraken',
  KRAKEN_REBALANCE = 'https://mercuryredstone.com/index-portefeuille-kraken/#herbalanceren',
  BITVAVO_LEARN_MORE = 'https://mercuryredstone.com/index-portefeuille-bitvavo/',
  BITVAVO_SIGNUP = 'https://bitvavo.com/?a=481BD3AD5C',
  BITVAVO_TERMS_LINK = 'https://mercuryredstone.com/api-koppeling-bitvavo/',
  BITVAVO_ADD_MONEY_LINK = 'https://support.bitvavo.com/hc/nl/articles/4405187872657-Euro-stortmogelijkheden-bij-Bitvavo',
  BITVAVO_STAKING = 'https://mercuryredstone.com/staking/',
  BITVAVO_REBALANCE = 'https://mercuryredstone.com/index-portefeuille-bitvavo/#herbalanceren',
  TERMS_LINK = 'https://mercuryredstone.com/algemene-voorwaarden/',
  MARKET_VECTOR_FACT_SHEETS = 'https://marketvector.com/factsheets/download/MVDA25.d.pdf',
  MARKET_VECTOR_METHODOLOGY = 'https://www.marketvector.com/rulebooks/download/MVDA25_Index_Guide.pdf',
  DISCLAIMER_LINK = 'https://mercuryredstone.com/risicos/',
  PRIVACY_LINK = 'https://mercuryredstone.com/cookie-en-privacyverklaring/',
  NOT_US_PERSON_LINK = 'https://www.taxesforexpats.com/expat-tax-advice/definition-us-person-for-irs.html',
  CONTACTS_LINK = 'https://mercuryredstone.com/contact/',
  FAQ_LINK = 'https://mercuryredstone.com/faq/',
  // FAQ_KRAKEN_LINK = 'https://mercuryredstone.com/faq/#kraken',
  KRAKEN_API_LINK = 'https://mercuryredstone.com/api-koppeling-kraken',
  BITVAVO_API_LINK = 'https://mercuryredstone.com/api-koppeling-bitvavo',
  SECURITY_LINK = 'https://mercuryredstone.com/handelsplatformen/#beveiligingstips',
  LINKED_IN_LINK = 'https://linkedin.com/company/mercuryredstone',
  FACEBOOK_LINK = 'https://www.facebook.com/Mercury-Redstone-2287358764680953',
  TWITTER_LINK = 'https://twitter.com/mercuryredstone',
  YOUTUBE_LINK = 'https://youtube.com/@mercuryredstone',
  HOW_IT_WORKS_LINK = 'https://mercuryredstone.com/hoe-het-werkt',
  GOOGLE_AUTHENTICATOR = 'https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=nl',
  CHATBOX_LINK = '//code.tidio.co/erzidcunoctdnrvxqsptbrlqkugoprmp.js',
  MARKET_VECTOR_INDEXES = 'https://mercuryredstone.com/mvis/',
  MVDA_25_INDEX = 'https://www.marketvector.com/indexes/digital-assets/marketvector-digital-assets-25',
}

const defaultTablesData = {
  initialRows: 100,
  rowsPerPageOptions: [100, 200, 500, { value: -1, label: 'All' }],
};

export const tablesData = {
  invoices: {
    initialRows: 50,
  },
  adminInvoices: defaultTablesData,
  adminUsers: {
    initialRows: 50,
    rowsPerPageOptions: [50, 100, 500, 700, 1000, { value: -1, label: 'All' }],
  },
};

export const externalLinkProps = {
  rel: 'noreferrer',
  target: '_blank',
};

export const defaultLanguageLocale = 'nl';

export const highstockCreditsLink = 'https://www.everviz.com/';

export const generalDataPollInterval = 30 * 1000; // each 30 seconds

export const investOptions = [
  // Was €1.000 initially, thus value 1 left, since it doesn't matter
  // on server
  { label: '€2.500 - €10.000', value: 1 },
  { label: '€10.000 - €50.000', value: 10 },
  { label: '€50.000 - €250.000', value: 50 },
  { label: '€250.000 +', value: 250 },
];

export enum serverResponseErrorsCodes {
  START_SUBSCRIPTION_NOT_ENOUGH_MONEY = 'not-enough-money-error',
  WITHDRAW_NOT_ENOUGH_LEFT_MONEY = 'not-enough-money-left-error',
  WITHDRAW_NOT_ENOUGH_MONEY_TO_WITHDRAW = 'not-enough-money-withdraw-error',
  WITHDRAW_NO_SUCH_CURRENCY_IN_INDEX_PORTFOLIO = 'currency-in-index-portfolio-error',
  RESET_PASSWORD_TIMEOUT = 'reset-password-timeout',
  WRONG_PASSWORD = 'wrong-password',
  CAPTCHA_V3_ERROR = 'captcha-error',
  CAPTCHA_V2_ERROR = 'captcha-v2-error',
  GOOGLE_EMAIL = 'google-email',
  GOOGLE_USER_NOT_FOUND = 'google-user-not-found',
  REFERAL_NOT_FOUND = 'referal-not-found',
  DEPOSIT_NO_SUCH_CURRENCY_IN_PRIVATE_PORTFOLIO = 'currency-in-private-portfolio-error',
}

export enum serverResponseErrorMessages {
  FAILED_TO_FETCH = 'Failed to fetch',
}

export enum LocalStorage {
  drawerDesktopShrinked = 'drawer_desktop_shrinked',
  adminDrawerDesktopShrinked = 'admin_drawer_desktop_shrinked',
  adminUsersTableFilters = 'admin_users_table_filters',
  adminUsersTableSortings = 'admin_users_table_sortings',
}

export const exchangesCodesImages: {
  [key in ExchangeCode]: string;
} = {
  [ExchangeCode.Bitvavo]: bitvavoLogoIcon,
  [ExchangeCode.Kraken]: krakenLogoIcon,
};
