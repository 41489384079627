/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useMemo, forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation, TFunction } from 'react-i18next';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { logout } from 'utils/auth';
import { colors } from 'utils/colors';
import { externalLinkProps, SiteData } from 'utils/consts';
import {
  FirstPage as ShrinkIcon,
  LastPage as MaximiseIcon,
} from '@mui/icons-material';
import {
  MenuList as DefList,
  MenuItem as DefMenuItem,
  ListItemIcon,
  ListItemText,
  Link as DefCustomLink,
  IconButton,
} from '@mui/material';
import { useServerErrorVar } from '../utils/apollo-vars';
import { useAdminSignOutAs, useIsTablet } from '../hooks';
import { useAppMenuDataQuery } from '../apollo';
import { getColor, getDownMedia } from '../styles';
import { useDrawerContext, usePayments } from 'providers';
import whiteIconLogo from '../assets/images/logo-white-icon.svg';
import { paths } from '../pages';
import { TrialClock } from './TrialClock';
import {
  DoubleRightIcon as DefDoubleRightIcon,
  PieOutlinedIcon,
  FileIcon,
  UserIcon,
  BubbleIcon,
  GearIcon,
  QuestionMarkInCircleIcon,
  SwitchOffIcon,
} from './icons';
import { FullLogo as DefFullLogo } from './logos';

const AppMenu = () => {
  const { t } = useTranslation();
  const serverError = useServerErrorVar();
  const isTablet = useIsTablet();
  const { type, desktopShrinked, dispatch } = useDrawerContext();
  const { paymentTrialClockActive } = usePayments();

  const { data: { getUser: user } = {} } = useAppMenuDataQuery();

  const isAdmin = user?.isAdmin;
  const controllingUser = user?.controlledByUser;

  const menuLinks = useMemo(() => getMenuLinks(t, !!isAdmin), [t, isAdmin]);

  const logo = useMemo(() => {
    if (isTablet) return null;

    const props = {
      onClick: () => window.open(SiteData.ROOT_LINK, '_self'),
    };

    return desktopShrinked ? (
      <LogoImage src={whiteIconLogo} {...props} />
    ) : (
      <FullLogo {...props} />
    );
  }, [isTablet, desktopShrinked]);

  const [signOutAs] = useAdminSignOutAs();

  if (serverError) return logo;

  return (
    <>
      {logo}
      <Menu>
        <MenuItems>
          {menuLinks.map(
            ({ title, Component = Link, icon, extraProps }, index) => (
              <li
                key={index}
                style={
                  extraProps && extraProps.marginTopAuto
                    ? { marginTop: 'auto' }
                    : {}
                }
              >
                {/*
                 // @ts-ignore */}
                <MenuItem key={icon} component={Component} {...extraProps}>
                  <MenuItemIcon>{icon}</MenuItemIcon>
                  <MenuItemText>{title}</MenuItemText>
                </MenuItem>
              </li>
            )
          )}
          {!!controllingUser && (
            <li>
              {/*
               // @ts-ignore */}
              <MenuItem component={CustomLink} onClick={() => signOutAs()}>
                <MenuItemIcon>
                  <SwitchOffIcon />
                </MenuItemIcon>
                <MenuItemText>{t('HEADER__adminLogoutText')}</MenuItemText>
              </MenuItem>
            </li>
          )}
        </MenuItems>
        <StyledClock hidden={type === 'desktop' && desktopShrinked} />
      </Menu>
      {!isTablet && !paymentTrialClockActive && (
        <DesktopShrinkButton
          disableRipple
          onClick={() => dispatch({ type: 'toggleDesktopShrink' })}
        >
          {desktopShrinked ? <MaximiseIcon /> : <ShrinkIcon />}
        </DesktopShrinkButton>
      )}
    </>
  );
};

type LinkExtraProps = {
  marginTopAuto?: boolean;
  onClick?: () => void;
};

const FullLogo = styled(DefFullLogo)`
  flex-shrink: 0;
  max-width: 100%;
  padding: 30px 34px 14px;
  cursor: pointer;

  ${({ theme: { drawer } }) =>
    drawer?.desktopShrinked &&
    css`
      display: none; ;
    `}
`;

const LogoImage = styled.img`
  max-width: 100%;
  padding: 16px;
`;

// noinspection CssUnusedSymbol
const MenuItemIcon = styled(ListItemIcon)`
  && {
    ${({ theme: { drawer } }) =>
      !drawer?.desktopShrinked &&
      css`
        height: 18px;
      `};
    min-width: ${({ theme: { drawer } }) =>
      drawer?.desktopShrinked ? 42 : 30}px;
  }

  transition: color 0.2s ease-in-out;
  color: #fff;

  > * {
    color: inherit;
  }
`;

const DoubleRightIcon = styled(DefDoubleRightIcon)``;

// noinspection CssUnusedSymbol
const MenuItemText = styled(ListItemText)`
  color: ${colors.white};
  transition: color 0.2s ease-in-out;
  position: relative;
  bottom: 1px;

  .MuiTypography-root {
    color: inherit;

    ${DoubleRightIcon} {
      width: 15px;
      height: 15px;
      position: relative;
      top: 4px;
      left: -1px;
      color: inherit;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      position: relative;
      bottom: 1px;
      font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    }
  }
`;

// noinspection CssUnusedSymbol
const commonLinksStyles = css`
  &:hover {
    background-color: transparent;

    &:not(.active) {
      ${MenuItemIcon}, ${MenuItemText} {
        color: ${colors.blue2};
      }
    }
  }
`;

// noinspection CssUnusedSymbol
const Link = styled(
  forwardRef<HTMLAnchorElement, NavLinkProps & LinkExtraProps>(
    ({ marginTopAuto, ...props }, ref) => <NavLink ref={ref} {...props} />
  )
)`
  ${commonLinksStyles};

  &.active {
    border-radius: ${({ theme: { drawer } }) =>
      drawer?.desktopShrinked ? 0 : 8}px;
    background-color: ${getColor('blue')};
    cursor: default;

    ${getDownMedia('md')} {
      border-radius: 8px;
    }
  }
`;

const CustomLink = styled(
  forwardRef<HTMLAnchorElement, LinkExtraProps>(
    ({ marginTopAuto, ...props }, ref) => <DefCustomLink ref={ref} {...props} />
  )
)`
  ${commonLinksStyles};
`;

// noinspection CssUnusedSymbol
const Menu = styled.nav`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .MuiList-padding {
    padding-top: ${({ theme: { drawer } }) =>
      drawer?.desktopShrinked ? 0 : 32}px;
    padding-left: ${({ theme: { drawer } }) =>
      drawer?.desktopShrinked ? 0 : 16}px;
    padding-right: ${({ theme: { drawer } }) =>
      drawer?.desktopShrinked ? 0 : 16}px;
    padding-bottom: 0;

    ${getDownMedia('md')} {
      padding-top: 20px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

const MenuItems = styled(DefList)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 0;
    margin-bottom: 4px;
  }

  li + li {
    margin-top: 8px;
  }
`;

// eslint-disable-next-line no-unexpected-multiline
const MenuItem = styled(DefMenuItem)<
  { component: typeof Link | typeof CustomLink } & NavLinkProps
>`
  padding: 8px
    ${({ theme: { drawer } }) => (drawer?.desktopShrinked ? 20 : 16)}px 8px;

  @media screen and (max-height: 700px) {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  ${getDownMedia('sm')} {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px),
    screen and (max-height: 300px) {
    min-height: 44px;
  }
`;

const StyledClock = styled(TrialClock)`
  margin: auto 20px 20px;

  ${getDownMedia('md')} {
    align-self: flex-start;
    width: calc(100% - 20px);
    max-width: 240px;
    margin: auto 38px 10px;
  }
`;

const DesktopShrinkButton = styled(IconButton)`
  margin-top: auto;
  margin-left: ${({ theme: { drawer } }) =>
    drawer?.desktopShrinked ? 4 : 14}px;
  margin-bottom: 20px;
  color: ${getColor('white')};
  font-size: 20px;
  align-self: flex-start;

  &:hover {
    background-color: transparent;
    color: ${getColor('blue2')};
  }
`;

const getMenuLinks: (
  t: TFunction,
  isAdmin: boolean
) => Array<{
  title: ReactNode;
  Component?: typeof CustomLink;
  icon: JSX.Element;
  extraProps: LinkExtraProps & Partial<NavLinkProps>;
}> = (t, isAdmin = false) => {
  const commonLinks = [
    {
      title: (
        <>
          {t('MENU__howItWorksLinkText')} <DoubleRightIcon />
        </>
      ),
      Component: CustomLink,
      icon: <GearIcon />,
      extraProps: {
        href: SiteData.HOW_IT_WORKS_LINK,
        ...externalLinkProps,
      },
    },
    {
      title: (
        <>
          {t('MENU__faqLinkText')} <DoubleRightIcon />
        </>
      ),
      Component: CustomLink,
      icon: <QuestionMarkInCircleIcon />,
      extraProps: {
        href: SiteData.FAQ_LINK,
        ...externalLinkProps,
      },
    },
    /*{
      title: (
        <>
          {t('MENU__helpLinkText')} <DoubleRightIcon />
        </>
      ),
      Component: CustomLink,
      icon: 'icon-chat',
      extraProps: {
        href: SiteData.CONTACTS_LINK,
        ...externalLinkProps,
      },
    },*/
    {
      title: t('MENU__logoutLinkText'),
      Component: CustomLink,
      icon: <SwitchOffIcon />,
      extraProps: {
        onClick: logout,
      },
    },
  ];
  const commonLinksForAdminPage = [
    {
      title: t('MENU__logoutLinkText'),
      Component: CustomLink,
      icon: <SwitchOffIcon />,
      extraProps: {
        onClick: logout,
      },
    },
  ];

  return isAdmin
    ? [
        {
          title: t('MENU__allInvoicesLinkText'),
          icon: <FileIcon />,
          extraProps: {
            to: paths.adminInvoices,
          },
        },
        {
          title: t('MENU__allUsersLinkText'),
          icon: <UserIcon />,
          extraProps: {
            to: paths.adminAllUsers,
          },
        },
        {
          title: t('MENU__accountLinkText'),
          icon: <UserIcon />,
          extraProps: {
            to: paths.adminAccount,
          },
        },
        ...commonLinksForAdminPage,
      ]
    : [
        {
          title: t('MENU__porfolioLinkText'),
          icon: <PieOutlinedIcon />,
          extraProps: {
            to: paths.dashboard,
          },
        },
        {
          title: t('MENU__invoicesLinkText'),
          icon: <FileIcon />,
          extraProps: {
            to: paths.invoices,
          },
        },
        {
          title: t('MENU__accountLinkText'),
          icon: <UserIcon />,
          extraProps: {
            to: paths.account,
          },
        },
        {
          title: t('MENU__helpLinkText'),
          icon: <BubbleIcon />,
          extraProps: {
            to: paths.contacts,
          },
        },
        ...commonLinks,
      ];
};

export { AppMenu };
