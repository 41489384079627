/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { PropsWithChildren, ComponentProps } from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash-es';
import { Paper } from '@mui/material';
import { ModalCloseButton, ModalCloseButtonProps } from './ModalCloseButton';

export type ModalContentWithButtonProps = PropsWithChildren<
  {
    closeButtonProps?: Partial<ModalCloseButtonProps>;
    close?: () => void;
  } & ComponentProps<typeof Wrapper>
>;

const ModalContentWithButton = ({
  children,
  closeButtonProps,
  close,
  ...props
}: ModalContentWithButtonProps) => (
  <Wrapper elavation={1} {...props}>
    {isFunction(close) && (
      <ModalCloseButton {...closeButtonProps} onClick={close} />
    )}
    {children}
  </Wrapper>
);

const Wrapper = styled(Paper)`
  padding: 40px;
  position: relative;
`;

export { ModalContentWithButton };
