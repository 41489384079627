/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import {
  useReducer,
  useMemo,
  createContext,
  useContext,
  useCallback,
  FC,
  PropsWithChildren,
  Dispatch,
} from 'react';
import { once } from 'lodash-es';
import { ModalWrapper, ModalWrapperProps } from '../components/modal-elements';
import {
  Disable2FAAdminModal,
  Disable2FAModal,
  DisableSubscriptionModal,
  DisableSubscriptionAdminModal,
  Enable2FAModal,
  Login2FAModal,
  Reminder2FAModal,
  RemoveAccountModal,
  ResetPasswordModal,
  SendEmailsAdminModal,
  RebalanceUserAdminModal,
  RemoveUserKeysAdminModal,
  InitialPortfolioModal,
  KrakenInfoModal,
  BitvavoInfoModal,
  ExpectedPortfolioModal,
  UpdateServiceWorkerModal,
  Disable2FAAdminModalData,
  DisableSubscriptionModalData,
  DisableSubscriptionAdminModalData,
  RemoveUserKeysAdminModalData,
  SendEmailsAdminModalData,
  RebalanceUserAdminModalData,
  KrakenInfoModalData,
  BitvavoInfoModalData,
  ExpectedPortfolioModalData,
} from '../components/modals';

const Modals: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { modalOpened, contentWrapperProps, Content } = state;
  const contextValue = useMemo(() => ({ ...state, dispatch }), [state]);
  const close = useCallback(() => dispatch({ type: 'closeModal' }), []);
  const ModalsContext = useMemo(() => createModalsContext(), []);

  return (
    <>
      <ModalsContext.Provider value={contextValue}>
        {children}
        <InModalContext.Provider value={true}>
          <ModalWrapper
            open={modalOpened}
            close={close}
            {...contentWrapperProps}
          >
            {!!Content && <Content />}
          </ModalWrapper>
        </InModalContext.Provider>
      </ModalsContext.Provider>
    </>
  );
};

type State<T extends Modal> = {
  modalOpened: boolean;
  contentWrapperProps: WithOptional<
    Omit<ModalWrapperProps, 'open' | 'onClose'>,
    'close'
  >;
  Content: typeof modals[T] | null;
  data: ModalsData[T] | undefined;
};

const initialState = {
  modalOpened: false,
  contentWrapperProps: {
    modalName: '',
  },
  Content: null,
  data: undefined,
};

const modals = {
  disable2FAAdmin: Disable2FAAdminModal,
  disable2FA: Disable2FAModal,
  disableSubscription: DisableSubscriptionModal,
  disableSubscriptionAdmin: DisableSubscriptionAdminModal,
  enable2FA: Enable2FAModal,
  login2FA: Login2FAModal,
  reminder2FA: Reminder2FAModal,
  removeAccount: RemoveAccountModal,
  resetPassword: ResetPasswordModal,
  sendEmailsAdmin: SendEmailsAdminModal,
  rebalanceUserAdmin: RebalanceUserAdminModal,
  removeUserKeysAdmin: RemoveUserKeysAdminModal,
  initialPortfolioModal: InitialPortfolioModal,
  krakenInfoModal: KrakenInfoModal,
  bitvavoInfoModal: BitvavoInfoModal,
  expectedPortfolio: ExpectedPortfolioModal,
  updateServiceWorker: UpdateServiceWorkerModal,
} as const;

export type Modal = keyof typeof modals;

type ModalsData = {
  disable2FAAdmin: Disable2FAAdminModalData;
  disable2FA: undefined;
  disableSubscription: DisableSubscriptionModalData;
  disableSubscriptionAdmin: DisableSubscriptionAdminModalData;
  enable2FA: undefined;
  login2FA: undefined;
  reminder2FA: undefined;
  removeAccount: undefined;
  resetPassword: undefined;
  sendEmailsAdmin: SendEmailsAdminModalData;
  rebalanceUserAdmin: RebalanceUserAdminModalData;
  removeUserKeysAdmin: RemoveUserKeysAdminModalData;
  initialPortfolioModal: undefined;
  krakenInfoModal: KrakenInfoModalData;
  bitvavoInfoModal: BitvavoInfoModalData;
  expectedPortfolio: ExpectedPortfolioModalData;
  updateServiceWorker: undefined;
};

type CloseModalAction = {
  type: 'closeModal';
};

type SetModalContentAction<T extends Modal> = {
  type: 'setModalContent';
  payload: {
    name: T;
    data?: ModalsData[T];
  };
};

type SetWrapperPropsAction<T extends Modal> = {
  type: 'setWrapperProps';
  payload: State<T>['contentWrapperProps'];
};

type Action<T extends Modal> =
  | CloseModalAction
  | SetModalContentAction<T>
  | SetWrapperPropsAction<T>;

const reducer = <T extends Modal>(
  prevState: State<T>,
  action: Action<T>
): State<T> => {
  switch (action.type) {
    case 'closeModal':
      return initialState;
    case 'setWrapperProps':
      return {
        ...prevState,
        contentWrapperProps: action.payload,
      };
    case 'setModalContent':
      const Content = modals[action.payload.name];

      if (!Content) {
        throw new Error(`No such modal name: ${action.payload.name}`);
      }

      return {
        ...prevState,
        modalOpened: true,
        Content,
        data: 'data' in action.payload ? action.payload.data : undefined,
      };
    default:
      return prevState;
  }
};

const createModalsContext = once(<T extends Modal>() =>
  createContext<
    State<T> & {
      dispatch: Dispatch<Action<T>>;
    }
  >({
    ...initialState,
    dispatch: () => undefined,
  })
);

const useModals = <T extends Modal>() => useContext(createModalsContext<T>());

const InModalContext = createContext<boolean>(false);
const useInModal = () => useContext(InModalContext);

export { Modals, useModals, useInModal };
