/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  ModalContentWithButton,
  ModalContentWithButtonProps,
} from './ModalContentWithButton';

export type ContentModalWhiteContentProps = ModalContentWithButtonProps;

const ContentModalWhiteContent = (
  props: PropsWithChildren<ContentModalWhiteContentProps>
) => <Wrapper {...props} />;

const Wrapper = styled(ModalContentWithButton)`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: auto;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: auto;
    max-width: 500px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    box-shadow: none;
  }
`;

export { ContentModalWhiteContent };
