/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { FC, PropsWithChildren } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { generalDataPollInterval } from '../utils/consts';
import {
  isAdminPath,
  isAppPath,
  isInnerAppPath,
  isServerError,
  isVerifyPagePath,
} from '../utils/helpers';
import { useWindowTabIsActive } from '../hooks';
import { UserStatus, useMainDataQuery } from '../apollo';
import { useAuthContext } from '../providers';
import { pages, paths } from '../pages';

const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const authorized = useAuthContext();

  const tabIsActive = useWindowTabIsActive();

  const {
    loading,
    error,
    data: { getUser: user } = {},
    refetch,
    startPolling,
    stopPolling,
  } = useMainDataQuery({
    skip: !authorized,
    fetchPolicy: 'network-only',
    pollInterval: generalDataPollInterval,
  });

  useUpdateEffect(() => {
    if (!authorized) return;
    if (tabIsActive) {
      refetch();
      startPolling(generalDataPollInterval);
    } else {
      stopPolling();
    }
  }, [authorized, tabIsActive, startPolling, stopPolling]);

  if (!authorized && location.search === '?exec_rebalance=started') {
    return <Navigate to={paths.rebalanceLogin} replace />;
  }

  if (!authorized) return <Navigate to={paths.login} replace />;

  if (loading) return <LoadingPage />;

  if (error && !isServerError(error)) {
    return <Navigate to={paths.logout} replace />;
  }

  const admin = user?.isAdmin;

  if (!isInnerAppPath(location)) return null;

  if (admin && !isAdminPath(location)) {
    return <Navigate to={paths.adminAllUsers} replace />;
  }

  if (isAdminPath(location) && !admin) {
    return <Navigate to={paths.dashboard} replace />;
  }

  const emailVerified = user?.status !== UserStatus.EmailNotVerified;

  const verifyPage = isVerifyPagePath(location);

  if (isAppPath(location) && !emailVerified && !verifyPage) {
    return <Navigate to={paths.verify} replace />;
  }

  if (emailVerified && verifyPage) {
    return <Navigate to={paths.dashboard} replace />;
  }

  return <>{children}</>;
};

const LoadingPage = pages.loading;

export { PrivateRoute };
