/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FileCopyOutlined } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { copyToClipboard } from '../../utils/clipboard';
import { getPrice } from '../../utils/currency';
import { useUserSubscriptionReferralCodeAndDiscountQuery } from '../../apollo';
import { getColor, getDownMedia, getUpMedia } from '../../styles';
import { useAlert } from '../../providers';
import { Spinner } from '../../styled';
import { Button as DefButton } from '../buttons';
import { Input as DefInput } from '../form-elements';
import { Text } from '../texts';
import { ContentBlock } from './ContentBlock';
import { SectionTitle } from './SectionTitle';
import { SectionWrapper } from './SectionWrapper';

const PromotionBlock = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [codeHidden, setCodeHidden] = useState(true);

  const {
    loading,
    error,
    data: { getUser: user } = {},
  } = useUserSubscriptionReferralCodeAndDiscountQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <ContentBlock>
      <SectionWrapper>
        <SectionTitle>{t('ACCOUNT_PAGE__promoTitle')}</SectionTitle>
        {(() => {
          if (!user) {
            if (loading) return <Spinner />;
            if (error) return <Text>Error on data load</Text>;
            return null;
          }

          return (
            <>
              <Desc paragraph>{t('ACCOUNT_PAGE__promoDescText1')}</Desc>
              <Desc weight={'semiBold'}>
                {t('ACCOUNT_PAGE__promoDescText2')}
              </Desc>
              <FormSection>
                <Button
                  disabled={!codeHidden}
                  onClick={() => {
                    setCodeHidden(false);
                  }}
                >
                  {t('ACCOUNT_PAGE__promoButton')}
                </Button>
                <Input
                  type={codeHidden ? 'password' : 'text'}
                  label={t('ACCOUNT_PAGE__promoCodeLabel')}
                  value={user.personalReferalCode}
                  focused={codeHidden ? false : undefined}
                  InputProps={{
                    readOnly: true,
                    endAdornment: !codeHidden ? (
                      <InputAdornment position="end">
                        <CopyIcon />
                      </InputAdornment>
                    ) : null,
                  }}
                  onClick={() => {
                    if (codeHidden) return;
                    copyToClipboard(user.personalReferalCode);
                    setAlert({
                      type: 'info',
                      message: t('ACCOUNT_PAGE__promoCopySuccess'),
                    });
                  }}
                />
                <Input
                  label={t('ACCOUNT_PAGE__promoAmountLabel')}
                  value={getPrice(user.pendingDiscount)}
                  focused={false}
                />
              </FormSection>
            </>
          );
        })()}
      </SectionWrapper>
    </ContentBlock>
  );
};

const Desc = styled(Text)``;

const breakpoint = 1014;
const FormSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;

  ${getDownMedia(breakpoint)} {
    flex-direction: column;
  }
`;

const commonStyles = css`
  flex: 0 0 32%;
`;

const Button = styled(DefButton)`
  ${commonStyles};

  ${getDownMedia(breakpoint)} {
    flex-basis: auto;
  }

  ${getDownMedia('md')} {
    margin-bottom: 8px;
  }
`;

const Input = styled(DefInput)`
  ${commonStyles};

  ${getUpMedia(1015)} {
    margin-top: 0;
    margin-bottom: 0;
  }

  /*noinspection CssUnusedSymbol*/
  .MuiInputBase-adornedEnd {
    padding-right: 8px;
  }
`;

const CopyIcon = styled(FileCopyOutlined)`
  font-size: 1.2rem;
  color: ${getColor('blue')};
  cursor: pointer;
`;

export { PromotionBlock };
