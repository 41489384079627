/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link as DefLink } from '@mui/material';
import { SiteData, externalLinkProps } from '../../utils/consts';
import moment from '../../utils/moment';
import { sendSentryError } from '../../utils/sentry';
import {
  useExpectedPortfolioQuery,
  ExchangeCode,
  usePortfolioRebalanceMutation,
} from '../../apollo';
import { useModals, useAlert } from '../../providers';
import { Spinner } from '../../styled';
import { Button as DefButton } from '../buttons';
import { ExpectedPortfolioTable } from '../portfolio';
import { Text as DefText } from '../texts';

const ExpectedPortfolioModal = () => {
  const { t } = useTranslation();
  const { data: modalData, dispatch } = useModals<'expectedPortfolio'>();
  const { setAlert } = useAlert();

  const { loading, error, data } = useExpectedPortfolioQuery({
    fetchPolicy: 'no-cache',
    skip: !modalData?.exchangeId,
    variables: {
      exchangeId: modalData!.exchangeId,
    },
    onError: (e) => {
      sendSentryError(e);
      setAlert({
        type: 'error',
        message: t('COMMON_ERROR'),
      });
    },
  });

  const [rebalancePortfolio, { loading: initiatingRebalance }] =
    usePortfolioRebalanceMutation({
      fetchPolicy: 'no-cache',
      variables: {
        exchangeId: modalData!.exchangeId,
      },
      refetchQueries: ['userExchangeProviderData', 'dashboardData'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setAlert({
          type: 'success',
          message: t('EXPECTED_PORTFOLIO_MODAL__mutationSuccess'),
        });
        dispatch({
          type: 'closeModal',
        });
      },
      onError: (e) => {
        sendSentryError(e);
        setAlert({
          type: 'error',
          message: t('COMMON_ERROR'),
        });
      },
    });

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'bitvavo-info',
        styleType: 'specialExpectedPortfolio',
        // alwaysContentScrollbar: true,
      },
    });
  }, [data, dispatch]);

  const href =
    modalData?.exchangeCode === ExchangeCode.Kraken
      ? SiteData.KRAKEN_REBALANCE
      : SiteData.BITVAVO_REBALANCE;

  return (
    <>
      <Header>
        <Title id={'bitvavo-info-modal-title'}>
          {t('EXPECTED_PORTFOLIO_MODAL__title')}
        </Title>
        <Desc component={'p'} variant={'subtitle2'}>
          {t('EXPECTED_PORTFOLIO_MODAL__desc')}
        </Desc>
        <Link href={href} {...externalLinkProps}>
          {t('EXPECTED_PORTFOLIO_MODAL__link')}
        </Link>
        <Date variant={'subtitle2'}>
          {t('EXPECTED_PORTFOLIO_MODAL__date', {
            date: moment().format('DD-MM-YYYY'),
          })}
        </Date>
      </Header>
      {(() => {
        if (loading) {
          return (
            <Spinner
              style={{
                margin: '20px auto',
              }}
            />
          );
        }

        if (error || !data?.getExpectedPortfolio) {
          return (
            <DefText
              style={{
                marginTop: 20,
              }}
            >
              {t('EXPECTED_PORTFOLIO_MODAL__dataLoadError')}
            </DefText>
          );
        }

        return (
          <>
            <ExpectedPortfolioTable
              style={{
                flexShrink: 0,
                overflowY: 'auto',
              }}
              portfolio={data.getExpectedPortfolio}
            />
            <Button
              loading={initiatingRebalance}
              onClick={() => rebalancePortfolio()}
            >
              {t('EXPECTED_PORTFOLIO_MODAL__button')}
            </Button>
          </>
        );
      })()}
    </>
  );
};

const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled(DefText).attrs({
  variant: 'h1',
})`
  margin-bottom: 12px;
  grid-column: 1 / -1;
`;

const Desc = styled(DefText)`
  grid-column: 1 / -1;
  margin-bottom: 16px;
`;

const Link = styled(DefLink)``;

const Date = styled(DefText)`
  justify-self: end;
`;

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 20px;
`;

export type ExpectedPortfolioModalData = {
  exchangeId: number;
  exchangeCode: ExchangeCode;
};

export { ExpectedPortfolioModal };
