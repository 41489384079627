/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
import { Text, TextProps } from '../texts';

export const Title = styled((props: TextProps) => (
  <Text variant={'h2'} align={'center'} {...props} />
))`
  white-space: pre-wrap;
`;
