/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useRef, useMemo, ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import {
  Table as DefTable,
  TableBody as DefTableBody,
  TableFooter,
  TableHead as DefTableHead,
  TableRow as DefTableRow,
} from '@mui/material';
import { animated } from '@react-spring/web';
import { getPrice } from '../../utils/currency';
import { semiBoldText } from '../../utils/fonts';
import { getPercentages } from '../../utils/percentages';
import {
  minPortfolioItemCostToRender,
  extendPortfolioTableDataItemWithIcon,
} from '../../utils/portfolio';
import { useHorizontalDemoScroll } from '../../hooks';
import {
  PortfolioCurrency,
  ExpectedPortfolioTokenStatus,
  ExpectedPortfolioQuery,
} from '../../apollo';
import { usePortfolioCurrency } from '../../providers';
import { Spinner } from '../../styled';
import {
  OrderCell,
  NameCell,
  PriceCell,
  ChangeCell,
  TokenAmountCell,
  ValueCell,
  PercentageCell,
  TableCell,
} from '../../styled/portfolio';
import { Text } from '../texts';
import { PortfolioTableRow, PortfolioTableRowData } from './PortfolioTableRow';

export type ExpectedPortfolioTableProps = {
  portfolio: ExpectedPortfolioQuery['getExpectedPortfolio'];
} & ComponentProps<typeof Wrapper>;

const ExpectedPortfolioTable = ({
  portfolio,
  ...props
}: ExpectedPortfolioTableProps) => {
  const { t } = useTranslation();

  const { currency } = usePortfolioCurrency();

  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const scroll = useHorizontalDemoScroll(tableWrapperRef);

  const rawTableData = useMemo(
    () =>
      portfolio.map(
        ({
          cost,
          usdCost,
          currency: { piechartColor, ...restCurrency },
          ...rest
        }) =>
          ({
            cost,
            usdCost,
            ...restCurrency,
            ...rest,
          } as Omit<PortfolioTableRowData, 'icon'>)
      ),
    [portfolio]
  );

  const totalPercentToRender = useMemo(
    () =>
      getPercentages(
        rawTableData.reduce((res, { percentage }) => res + percentage, 0),
        {
          round: true,
        }
      ),
    [rawTableData]
  );

  const {
    loading: loadingTableData,
    error,
    value: tableData = [],
  } = useAsync(() => {
    const dataWithoutIcon = rawTableData.filter(
      ({ cost }) => minPortfolioItemCostToRender <= cost
    );
    return extendPortfolioTableDataItemWithIcon(dataWithoutIcon);
  }, [rawTableData]);

  const [fiats, unsortedCryptos] = useMemo(
    () =>
      tableData.reduce<[PortfolioTableRowData[], PortfolioTableRowData[]]>(
        (acc, data) =>
          data.isFiat
            ? [[...acc[0], data], acc[1]]
            : [acc[0], [...acc[1], data]],
        [[], []]
      ),
    [tableData]
  );

  const cryptos = useMemo(
    () =>
      unsortedCryptos.sort((a, b) => {
        if (
          a.status === ExpectedPortfolioTokenStatus.Removed &&
          b.status !== ExpectedPortfolioTokenStatus.Removed
        ) {
          return 1;
        }

        if (
          a.status !== ExpectedPortfolioTokenStatus.Removed &&
          b.status === ExpectedPortfolioTokenStatus.Removed
        ) {
          return -1;
        }

        return 0;
      }),
    [unsortedCryptos]
  );

  const smallTokensData = useMemo(() => {
    const rawData = portfolio
      .filter(({ cost }) => cost < minPortfolioItemCostToRender)
      .reduce(
        (acc, { cost, usdCost, percentage }) => ({
          amount:
            acc.amount + (currency === PortfolioCurrency.Eur ? cost : usdCost),
          percent: acc.percent + percentage,
        }),
        { amount: 0, percent: 0 }
      );

    return rawData.amount >= 0.01
      ? {
          amount: getPrice(
            rawData.amount,
            currency === PortfolioCurrency.Usd
              ? {
                  currency: 'USD',
                }
              : undefined
          ),
          percent: getPercentages(rawData.percent),
        }
      : null;
  }, [currency, portfolio]);

  const portfolioTotalCost = useMemo(() => {
    const costAmount = (portfolio || [])
      .map(({ cost, usdCost }) =>
        currency === PortfolioCurrency.Eur ? cost : usdCost
      )
      .reduce((res, cost) => res + cost, 0);

    return getPrice(
      costAmount,
      currency !== PortfolioCurrency.Eur
        ? {
            currency: 'USD',
          }
        : undefined
    );
  }, [portfolio, currency]);

  const renderTableRow = (data: PortfolioTableRowData, index: number) => (
    <PortfolioTableRow key={index} data={data} index={index} withFiatIndex />
  );

  return (
    <Wrapper ref={tableWrapperRef} scrollLeft={scroll} {...props}>
      {(() => {
        if (loadingTableData) {
          return (
            <Spinner
              style={{
                margin: 'auto',
              }}
            />
          );
        }

        if (error) {
          return (
            <Text
              style={{
                width: '100%',
              }}
              align={'center'}
            >
              Error
            </Text>
          );
        }

        return (
          <Table>
            <TableHead>
              <DefTableRow>
                <OrderCell />
                <NameCell>{t('DASHBOARD_PAGE__portfolioNameColumn')}</NameCell>
                <PriceCell>
                  {t('DASHBOARD_PAGE__portfolioPriceColumn')}
                </PriceCell>
                <ChangeCell>
                  {t('DASHBOARD_PAGE__portfolioChangeColumn')}
                </ChangeCell>
                <TokenAmountCell>
                  {t('DASHBOARD_PAGE__portfolioCoinsColumn')}
                </TokenAmountCell>
                <ValueCell>
                  {t('DASHBOARD_PAGE__portfolioValueColumn')}
                </ValueCell>
                <PercentageCell>
                  {t('DASHBOARD_PAGE__portfolioPercentageColumn')}
                </PercentageCell>
              </DefTableRow>
            </TableHead>
            <TableBody>
              {[...fiats, ...cryptos].map(renderTableRow)}
              {!!smallTokensData && (
                <DefTableRow>
                  <OrderCell />
                  <NameCell>
                    {t('DASHBOARD_PAGE__portfolioSmallTokensSumTitle')}
                  </NameCell>
                  <TableCell colSpan={3} />
                  <ValueCell>{smallTokensData.amount}</ValueCell>
                  <PercentageCell>{smallTokensData.percent}</PercentageCell>
                </DefTableRow>
              )}
            </TableBody>
            <TableFooter>
              <DefTableRow>
                <OrderCell />
                <NameCell>{t('DASHBOARD_PAGE__portfolioTotal')}</NameCell>
                <TableCell colSpan={3} />
                <ValueCell>{portfolioTotalCost}</ValueCell>
                <PercentageCell>{totalPercentToRender}</PercentageCell>
              </DefTableRow>
            </TableFooter>
          </Table>
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled(animated.div)<{
  $loading: boolean;
}>`
  display: flex;
  overflow-x: auto;
  ${({ $loading }) =>
    $loading &&
    css`
      min-height: 60px;
    `}
`;

const Table = styled(DefTable)`
  min-width: 100%;
  width: auto;
  flex: 1 1 auto;
  table-layout: fixed;

  /*noinspection CssUnusedSymbol*/
  .MuiTableCell-root {
    border-bottom: none;
  }

  /*noinspection CssUnusedSymbol*/
  .MuiTableCell-footer {
    ${semiBoldText};
  }
`;

const TableHead = styled(DefTableHead)`
  /*noinspection CssUnusedSymbol*/
  .MuiTableCell-head {
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: none;
    ${semiBoldText};
  }
`;

const TableBody = styled(DefTableBody)``;

export { ExpectedPortfolioTable };
