/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { sendSentryError } from '../../utils/sentry';
import { useModals } from '../../providers';
import { Button as DefButton } from '../buttons';
import { Text } from '../texts';

// Should be removed along with useHandlingServiceWorkerUpdate
const UpdateServiceWorkerModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals<'updateServiceWorker'>();

  const [updating, setUpdating] = useState(false);

  const {
    loading: creatingButtonPressFn,
    error,
    value: onButtonPress,
  } = useAsync(async () => {
    if (!('serviceWorker' in navigator)) {
      throw new Error(
        'Service worker not supported in UpdateServiceWorkerModal'
      );
    }

    const registration = await navigator.serviceWorker.ready;

    if (!registration.waiting) {
      throw new Error('No waiting service worker in UpdateServiceWorkerModal');
    }

    registration.waiting!.addEventListener('statechange', (event) => {
      // @ts-ignore
      const state = event?.target?.state;

      if (state === 'activating') return;

      if (state === 'activated') {
        window.location.reload();
        return;
      }

      sendSentryError(
        new Error(
          `Got unexpected state: ${state} in UpdateServiceWorkerModal. Consider to handle it.`
        )
      );
    });

    return () => {
      setUpdating(true);
      registration.waiting!.postMessage({ type: 'SKIP_WAITING' });
    };
  }, []);

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'update-service-worker',
        titleText: t('UPDATE_SERVICE_WORKER_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  useEffect(() => {
    if (error) {
      sendSentryError(error);
    }
  }, [error]);

  // Reset updating if takes for too long
  useEffect(() => {
    if (!updating) return;

    const tid = setTimeout(() => {
      setUpdating(false);
    }, 10000);

    return () => {
      clearTimeout(tid);
    };
  }, [updating]);

  return (
    <>
      <Text>{t('UPDATE_SERVICE_WORKER_MODAL__desc')}</Text>
      {!error ? (
        <Button
          loading={creatingButtonPressFn || updating}
          disabled={!isFunction(onButtonPress)}
          onClick={onButtonPress}
        >
          {t('UPDATE_SERVICE_WORKER_MODAL__button')}
        </Button>
      ) : (
        <Text>{t('COMMON_ERROR')}</Text>
      )}
    </>
  );
};

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 16px;
`;

export { UpdateServiceWorkerModal };
