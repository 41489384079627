/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useEffect } from 'react';
import { toNumber } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeSavedURLParams } from '../utils/saved-url-params';
import { useModals, useExchange } from '../providers';
import { useURLParams } from './useURLParams';

export const useUrlExpectedPortfolioModal = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { exchange } = useExchange();
  const { dispatch } = useModals();
  const { urlSearchParams } = useURLParams();

  useEffect(() => {
    if (!exchange?.exchangeID) return;

    const shouldBeTriggered =
      urlSearchParams.get('exec_rebalance') === 'compare';

    if (shouldBeTriggered) {
      dispatch({
        type: 'setModalContent',
        payload: {
          name: 'expectedPortfolio',
          data: {
            exchangeId: toNumber(exchange.exchangeID),
            exchangeCode: exchange.exchangeCode,
          },
        },
      });

      navigate(
        {
          pathname,
          search: '',
        },
        { replace: true }
      );
      removeSavedURLParams();
    }
  }, [
    pathname,
    exchange?.exchangeID,
    exchange?.exchangeCode,
    urlSearchParams,
    dispatch,
    navigate,
  ]);
};
