/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { sendSentryError } from '../../utils/sentry';
import { useModals, useExchange, useAlert } from '../../providers';
import { IndexTablePopup } from '../../styled/portfolio';
import { Text } from '../alert-modals/Text';
import { Title } from '../alert-modals/Title';
import { Button as DefButton } from '../buttons';

export type NewRebalancePopupProps = ComponentProps<typeof IndexTablePopup>;

const NewRebalancePopup = (props: NewRebalancePopupProps) => {
  const { t } = useTranslation();
  const { dispatch } = useModals();
  const { setAlert } = useAlert();
  const { exchange } = useExchange();

  const onClick = () => {
    if (!exchange?.exchangeID) {
      sendSentryError(
        new Error('There is no exchange id to open ExpectedPortfolioModal')
      );
      setAlert({
        type: 'error',
        message: t('COMMON_ERROR'),
      });
    } else {
      dispatch({
        type: 'setModalContent',
        payload: {
          name: 'expectedPortfolio',
          data: {
            exchangeId: toNumber(exchange.exchangeID),
            exchangeCode: exchange.exchangeCode,
          },
        },
      });
    }
  };

  return (
    <IndexTablePopup square={false} {...props}>
      <Title>{t('INDEX_PORTFOLIO_TABLE_NEW_REBALANCE_POPUP__title')}</Title>
      <Text>{t('INDEX_PORTFOLIO_TABLE_NEW_REBALANCE_POPUP__desc')}</Text>
      <Button onClick={onClick}>
        {t('INDEX_PORTFOLIO_TABLE_NEW_REBALANCE_POPUP__button')}
      </Button>
    </IndexTablePopup>
  );
};

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 20px;
`;

export { NewRebalancePopup };
