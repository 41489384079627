/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

const UserIcon = (props: IconProps) => (
  <Icon viewBox="0 0 19 20" {...props}>
    <g fill="currentColor">
      <path d="M9.5 11C6.5 11 4 8.5 4 5.5S6.5 0 9.5 0 15 2.5 15 5.5 12.5 11 9.5 11zm0-10C7 1 5 3 5 5.5S7 10 9.5 10 14 8 14 5.5 12 1 9.5 1zM17.5 20h-16C.7 20 0 19.3 0 18.5c0-.1 0-1.7 1.2-3.3.7-.9 1.7-1.7 2.9-2.2 1.5-.7 3.3-1 5.4-1s3.9.3 5.4 1c1.2.5 2.2 1.3 2.9 2.2 1.2 1.6 1.2 3.2 1.2 3.3 0 .8-.7 1.5-1.5 1.5zm-8-7c-3.5 0-6.1 1-7.4 2.8-1 1.4-1.1 2.7-1.1 2.7 0 .3.2.5.5.5h16c.3 0 .5-.2.5-.5 0 0 0-1.4-1.1-2.7C15.6 14 13 13 9.5 13z" />
    </g>
  </Icon>
);

export { UserIcon };
