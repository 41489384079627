/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';
import blueCheckImg from '../../assets/images/check-in-circle-blue.svg';
import greenCheckImg from '../../assets/images/check-in-circle-green.svg';
import crossImg from '../../assets/images/cross-in-circle.svg';
import lockedLock from '../../assets/images/locked-lock-red.svg';
import sadFaceImg from '../../assets/images/sad-face-in-circle.svg';
import unlockedLock from '../../assets/images/unlocked-lock-green.svg';

export type IconProps = {
  type: IconType;
} & ImgProps;

const Icon = ({ type, ...extraProps }: IconProps) => (
  <Img {...props[type]} {...extraProps} />
);

type IconType =
  | 'successBlue'
  | 'successGreen'
  | 'error'
  | 'sadFace'
  | 'lockedLock'
  | 'unlockedLock';

type ImgProps = ImgHTMLAttributes<HTMLImageElement>;

const props: Record<IconType, ImgProps> = {
  error: {
    src: crossImg,
    alt: 'Error',
  },
  sadFace: {
    src: sadFaceImg,
    alt: 'Sad face',
  },
  lockedLock: {
    src: lockedLock,
    alt: 'Locked lock',
  },
  unlockedLock: {
    src: unlockedLock,
    alt: 'Unlocked lock',
  },
  successBlue: {
    src: blueCheckImg,
    alt: 'Success',
  },
  successGreen: {
    src: greenCheckImg,
    alt: 'Success',
  },
};

const Img = styled.img`
  align-self: center;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
`;

export { Icon };
