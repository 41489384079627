/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useState, useMemo, ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { Collapse } from '@mui/material';
import { externalLinkProps, SiteData } from '../../utils/consts';
import { semiBoldText } from '../../utils/fonts';
import { ExchangeCode } from '../../apollo';
import {
  getColor,
  getUpMedia,
  resetButtonStyles,
  getDownMedia,
} from '../../styles';
import { useExchange } from '../../providers';
import marketVector from '../../assets/images/market-vector-logo.svg';
import { BlockTitle, BlockText as DefBlockText } from '../../styled/portfolio';
import { DownloadIcon } from '../icons';

export type PortfolioIndexDescriptionProps = ComponentProps<typeof Wrapper>;

const PortfolioIndexDescription = (props: PortfolioIndexDescriptionProps) => {
  const { t } = useTranslation();
  const { exchange } = useExchange();

  const [collapsed, setCollapsed] = useState(true);

  const collapseText = useMemo(
    () =>
      collapsed
        ? t('PORTFOLIO_INDEX_DESC__collapseMoreButton') + ' v'
        : t('PORTFOLIO_INDEX_DESC__collapseLessButton') + ' ∧',
    [t, collapsed]
  );

  const learnMoreLink =
    exchange?.exchangeCode === ExchangeCode.Kraken
      ? SiteData.KRAKEN_LEARN_MORE
      : SiteData.BITVAVO_LEARN_MORE;

  const onCollapseButtonClick = () => {
    setCollapsed((prevValue) => !prevValue);
  };

  return (
    <Wrapper {...props}>
      <Collapse in={!collapsed} collapsedSize={408}>
        <HeaderImage
          src={marketVector}
          alt="Market Vector"
          role="heading"
          aria-level={2}
          aria-label="Market Vector"
        />
        <BlockText>
          <Trans
            i18nKey="PORTFOLIO_INDEX_DESC__desc1"
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={[
              <Link
                href={SiteData.MARKET_VECTOR_INDEXES}
                {...externalLinkProps}
              />,
            ]}
          ></Trans>
        </BlockText>
        <BlockText>
          <Trans
            i18nKey="PORTFOLIO_INDEX_DESC__desc2"
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={[
              <Link href={SiteData.MVDA_25_INDEX} {...externalLinkProps} />,
            ]}
          ></Trans>
        </BlockText>
        <BlockText>{t('PORTFOLIO_INDEX_DESC__desc3')}</BlockText>
        <BlockText $noBottomMargin>
          <Link href={learnMoreLink} {...externalLinkProps}>
            {t('PORTFOLIO_INDEX_DESC__desc4')}
          </Link>
        </BlockText>
        <SubBlock>
          <BlockTitle>{t('PORTFOLIO_INDEX_DESC__funcTitle')}</BlockTitle>
          <ul>
            <li>{t('PORTFOLIO_INDEX_DESC__func1')}</li>
            <li>{t('PORTFOLIO_INDEX_DESC__func2')}</li>
            <li>{t('PORTFOLIO_INDEX_DESC__func3')}</li>
          </ul>
        </SubBlock>
        <SubBlock>
          <BlockTitle>{t('PORTFOLIO_INDEX_DESC__docsTitle')}</BlockTitle>
          <DocsLink
            href={SiteData.MARKET_VECTOR_FACT_SHEETS}
            {...externalLinkProps}
          >
            <span>{t('PORTFOLIO_INDEX_DESC__docsFactSheet')}</span>
            <DocsLinkIcon />
          </DocsLink>
          <DocsLink
            href={SiteData.MARKET_VECTOR_METHODOLOGY}
            {...externalLinkProps}
          >
            <span>{t('PORTFOLIO_INDEX_DESC__docsMethodology')}</span>
            <DocsLinkIcon />
          </DocsLink>
        </SubBlock>
      </Collapse>
      <CollapseButton $collapsed={collapsed} onClick={onCollapseButtonClick}>
        {collapseText}
      </CollapseButton>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding-top: 30px;
  position: relative;

  ${getDownMedia('md')} {
    padding-bottom: 48px;
  }

  ${getUpMedia('sm')} {
    && {
      display: grid;
    }
    & {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
    }
  }

  ${getUpMedia('lg')} {
    grid-template-columns: 1fr;
  }
`;

const HeaderImage = styled.img`
  max-width: 240px;
  margin-bottom: 30px;

  ${getUpMedia('sm')} {
    grid-column: 1 / -1;
  }
`;

const BlockText = styled(DefBlockText)<{
  $noBottomMargin?: boolean;
}>`
  ${getUpMedia('sm')} {
    grid-column: 1 / -1;
  }

  ${({ $noBottomMargin }) =>
    !!$noBottomMargin &&
    css`
      margin-bottom: 0;
    `}
`;

const Link = styled.a`
  ${semiBoldText};
`;

const SubBlock = styled.section`
  margin-top: 30px;
  ul {
    padding-left: 0;
    margin: 8px 0 0 15px;
    list-style-position: outside;

    li + li {
      margin-top: 5px;
    }
  }
`;

const DocsLink = styled(Link)`
  display: flex;
  max-width: 280px;
  justify-content: space-between;
  border-bottom: 1px solid ${getColor('lightGray')};
  padding: 6px 0;

  & + & {
    margin-top: 4px;
  }
`;

const DocsLinkIcon = styled(DownloadIcon)`
  margin-left: 10px;
  color: ${getColor('blue2')};
`;

const CollapseButton = styled.button<{
  $collapsed: boolean;
}>`
  ${resetButtonStyles};
  ${semiBoldText};
  color: ${getColor('blue')};
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);

  ${getDownMedia('md')} {
    bottom: 16px;
  }
`;

export { PortfolioIndexDescription };
