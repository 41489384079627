/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

const FileIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16.841 20.001" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M16.736 4.84 12 .104A.863.863 0 0 0 11.58 0H1.578C.737 0-.001.738-.001 1.58V18.42c0 .842.738 1.58 1.58 1.58H15.26c.842 0 1.58-.738 1.58-1.58V5.261c0-.104 0-.256-.105-.42zm-1.159.421h-3.472c-.317 0-.526-.209-.526-.525V1.263zm-.316 13.685H1.579c-.317 0-.529-.208-.529-.525V1.58c0-.317.212-.53.529-.53h8.946v3.686c0 .842.738 1.58 1.58 1.58h3.684V18.42c0 .317-.212.525-.528.525z" />
      <path d="M4 10a.528.528 0 1 1 0-1.054h8.839a.527.527 0 1 1 0 1.054zM4 13.052a.527.527 0 1 1 0-1.054h8.839a.527.527 0 1 1 0 1.054zM4 16.104a.527.527 0 1 1 0-1.05l8.839-.001c.293 0 .529.236.529.525s-.236.526-.53.526z" />
    </g>
  </Icon>
);

export { FileIcon };
