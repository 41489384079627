/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { AdminAllUsersPage } from './admin';
import {
  AccountPage,
  DashboardPage,
  InvoicesPage,
  VerifyPage,
  ContactsPage,
} from './app';
import {
  LoginPage,
  ResetPasswordPage,
  SignupPage,
  RebalanceLoginPage,
} from './auth';
import { LoadingPage, LogoutPage } from './common';
import { PayInvoicePage, NotFound } from './public';

export const pages = {
  loading: LoadingPage,
  notFound: NotFound,
  logout: LogoutPage,
  payInvoice: PayInvoicePage,
  login: LoginPage,
  resetPassword: ResetPasswordPage,
  signup: SignupPage,
  rebalanceLogin: RebalanceLoginPage,
  account: AccountPage,
  dashboard: DashboardPage,
  invoices: InvoicesPage,
  contacts: ContactsPage,
  verify: VerifyPage,
  adminAllUsers: AdminAllUsersPage,
  adminInvoices: InvoicesPage,
  adminAccount: AccountPage,
} as const;

export type PageName = keyof typeof pages;
