/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

const QuestionMarkInCircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 19 19" {...props}>
    <g fill="currentColor">
      <path d="M16.2 2.8C14.4 1 12 0 9.5 0S4.6 1 2.8 2.8 0 7 0 9.5s1 4.9 2.8 6.7S7 19 9.5 19s4.9-1 6.7-2.8S19 12 19 9.5s-1-4.9-2.8-6.7zM9.5 18C4.8 18 1 14.2 1 9.5S4.8 1 9.5 1 18 4.8 18 9.5 14.2 18 9.5 18z" />
      <path d="M9.5 14c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5 1.9 0 3.5-1.6 3.5-3.5S11.4 4 9.5 4 6 5.6 6 7.5c0 .3-.2.5-.5.5S5 7.8 5 7.5C5 5 7 3 9.5 3S14 5 14 7.5c0 2.3-1.8 4.2-4 4.5v1.5c0 .3-.2.5-.5.5zM9.5 17c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5z" />
    </g>
  </Icon>
);

export { QuestionMarkInCircleIcon };
