/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isEmpty, toNumber, uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { minPortfolioItemCostToRender, getCryptoIconPath } from '../../utils/portfolio';
import { sendSentryError } from '../../utils/sentry';
import { useAlertModal, usePortfolioDeposit } from 'hooks';
import { DashboardDataQuery, PortfolioPartitionAction } from '../../apollo';
import { useExchange, useAlert } from '../../providers';
import { Spinner } from '../../styled';
import { BlockTitle, BlockText as DefBlockText } from '../../styled/portfolio';
import {
  PortfolioManipulationForm,
  PortfolioManipulationFormProps,
} from '../forms';

type IndexPortfolioDepositProps = {
  portfolio: DashboardDataQuery['getPrivatePortfolio'];
} & HTMLAttributes<HTMLDivElement>;

const IndexPortfolioDeposit = ({
  portfolio,
  ...props
}: IndexPortfolioDepositProps) => {
  const { t } = useTranslation();
  const { exchange } = useExchange();
  const { setAlert } = useAlert();
  const { dispatch: dispatchAlertModal } = useAlertModal();

  const { loading: loadingCurrenciesOptions, value: currenciesOptions = [] } =
    useAsync(async () => {
      const [fiatOptions] = await Promise.all([
        Promise.all(
          portfolio
            .filter(({ cost }) => cost >= minPortfolioItemCostToRender)
            .sort((first, second) =>
              second.cost > first.cost ? 1 : second.cost === first.cost ? 1 : -1
            )
            .map(async ({ currency: { id, symbol } }) => ({
              label: symbol,
              value: id,
              icon: await getCryptoIconPath(symbol),
            }))
        ),
      ]);
      return uniqBy([...fiatOptions], 'value');
    }, [portfolio]);

  const formDisabled = useMemo(() => exchange?.sentToRebalancing, [exchange]);

  const [deposit] = usePortfolioDeposit({
    onCompleted: () => {
      dispatchAlertModal(['setModalContent', 'pendingExecRebalance']);
    },
  });

  const onSubmit: PortfolioManipulationFormProps['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      if (!exchange?.exchangeID) {
        throw new Error('No exchange id in deposit mutation');
      }

      await deposit({
        variables: {
          exchangeId: toNumber(exchange.exchangeID),
          currencyId: toNumber(values.currencyId),
          percent: toNumber(values.percent),
        },
      });

      resetForm();
    } catch (e) {
      sendSentryError(e);
      setAlert({
        type: 'error',
        message: t('COMMON_ERROR'),
      });
    }
  };

  return (
    <Wrapper {...props}>
      <BlockTitle>{t('INDEX_PORTFOLIO_DEPOSIT_DESC__title')}</BlockTitle>
      <BlockText>{t('INDEX_PORTFOLIO_DEPOSIT_DESC__text1')}</BlockText>
      {loadingCurrenciesOptions && isEmpty(currenciesOptions) ? (
        <Spinner
          style={{
            margin: '20px auto',
          }}
        />
      ) : (
        <FormWrapper>
          <BlockText weight={'semiBold'}>
            {t('INDEX_PORTFOLIO_DEPOSIT__formTitle')}
          </BlockText>
          <PortfolioManipulationForm
            portfolioManipulationAction={PortfolioPartitionAction.Deposit}
            options={currenciesOptions}
            inputsPrefix={'deposit'}
            disabled={formDisabled}
            buttonText={'INDEX_PORTFOLIO_DEPOSIT_DESC__buttonText'}
            onSubmit={onSubmit}
          />
        </FormWrapper>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  margin-top: auto;
`;

const BlockText = styled(DefBlockText)`
  margin-bottom: 16px;
`;

export { IndexPortfolioDeposit };
