/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  List as DefList,
  ListItem as DefListItem,
  ListItemText as DefListItemText,
  Link,
} from '@mui/material';
import { saveTxtFile } from '../utils/save-txt-file';
import { Button as DefButton } from './buttons';

type Props = {
  className?: string;
  codes: string[];
  onButtonClick: () => void;
};

const BackupCodes = ({ className, codes, onButtonClick }: Props) => {
  const { t } = useTranslation();

  const onSaveCodesButtonPress = () => {
    saveTxtFile(`Your codes:\r\n\r\n${codes.join('\r\n')}`, 'backup_codes.txt');
  };

  return (
    <Wrapper className={className}>
      <List>
        {codes.map((code) => (
          // @ts-ignore
          <ListItem key={code}>
            <ListItemText primary={code} />
          </ListItem>
        ))}
      </List>
      <Link onClick={onSaveCodesButtonPress}>
        {t('ENABLE_2FA_MODAL__backupSaveCodesButtonText')}
      </Link>
      <Button onClick={onButtonClick}>
        {t('ENABLE_2FA_MODAL__backupProceedButtonText')}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 20px;
`;

const List = styled(DefList)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 0;
  padding-bottom: 20px;
`;

const ListItem = styled(DefListItem)`
  padding-left: 0;
  flex: 0 0 50%;
`;

const ListItemText = styled(DefListItemText)``;

const Button = styled(DefButton)`
  && {
    width: 100%;
  }
  margin-top: 20px;
`;

export { BackupCodes };
