/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

const SwitchOffIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16.3 18.4" {...props}>
    <g fill="currentColor">
      <path d="M8.2 10.8c-.3 0-.5-.2-.5-.5V.5c0-.3.2-.5.5-.5s.5.2.5.5v9.8c0 .2-.2.5-.5.5z" />
      <path d="M8.2 18.4C6 18.4 4 17.6 2.4 16S0 12.4 0 10.2c0-1.8.6-3.5 1.6-4.9C2.7 4 4.2 2.9 5.9 2.4c.3-.1.6.1.6.4.1.3 0 .6-.3.7-3 .9-5 3.7-5 6.8 0 3.9 3.2 7.1 7.1 7.1s7.1-3.2 7.1-7.1c0-3.1-2.1-5.9-5-6.8-.3-.1-.5-.4-.4-.7s.2-.5.5-.4c1.6.5 3.1 1.5 4.2 2.9s1.6 3.1 1.6 4.9c0 2.2-.8 4.2-2.4 5.8-1.5 1.6-3.5 2.4-5.7 2.4z" />
    </g>
  </Icon>
);

export { SwitchOffIcon };
