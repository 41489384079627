/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { getDownMedia } from '../../styles';
import { TableCell } from './TableCell';

export const NameCell = styled(TableCell)`
  padding: 10px 12px 8px 4px;
  background-color: #fff;
  white-space: pre-line;

  ${getDownMedia('md')} {
    padding-right: 6px;
    position: sticky;
    left: 0;
    z-index: 1;
  }

  > div {
    min-width: 100px;
    display: inline-flex;
    align-items: center;
  }
`;
