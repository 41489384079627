/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useRef, useMemo, ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import {
  Table as DefTable,
  TableBody as DefTableBody,
  TableFooter,
  TableHead as DefTableHead,
  TableRow as DefTableRow,
} from '@mui/material';
import { animated } from '@react-spring/web';
import { getPrice } from '../../utils/currency';
import { semiBoldText } from '../../utils/fonts';
import { getPercentages } from '../../utils/percentages';
import {
  minPortfolioItemCostToRender,
  extendPortfolioTableDataItemWithIcon,
} from '../../utils/portfolio';
import { useHorizontalDemoScroll } from '../../hooks';
import {
  PortfolioCurrency,
  LastRebalanceReasonType,
  DashboardDataQuery,
} from '../../apollo';
import { useExchange, usePortfolioCurrency } from '../../providers';
import { Spinner } from '../../styled';
import {
  OrderCell,
  NameCell,
  PriceCell,
  ChangeCell,
  TokenAmountCell,
  ValueCell,
  PercentageCell,
  TableCell,
} from '../../styled/portfolio';
import { Text } from '../texts';
import { BuySellOrderFailedPopup as DefBuySellOrderFailedPopup } from './BuySellOrderFailedPopup';
import { NewRebalancePopup as DefNewRebalancePopup } from './NewRebalancePopup';
import { OrderInProgressPopup as DefOrderInProgressPopup } from './OrderInProgressPopup';
import { PortfolioTableRow, PortfolioTableRowData } from './PortfolioTableRow';
import { RebalanceFailedPopup as DefRebalanceFailedPopup } from './RebalanceFailedPopup';
import { RebalanceInProgressPopup as DefRebalanceInProgressPopup } from './RebalanceInProgressPopup';

export type IndexPortfolioTableProps = {
  portfolio: DashboardDataQuery['getIndexPortfolio'];
} & ComponentProps<typeof Wrapper>;

const IndexPortfolioTable = ({
  portfolio,
  ...props
}: IndexPortfolioTableProps) => {
  const { t } = useTranslation();
  const { exchange } = useExchange();

  const { currency } = usePortfolioCurrency();

  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const rawTableData = useMemo(
    () =>
      portfolio.map(
        ({
          cost,
          usdCost,
          currency: { piechartColor, ...restCurrency },
          ...rest
        }) =>
          ({
            cost,
            usdCost,
            ...restCurrency,
            ...rest,
          } as Omit<PortfolioTableRowData, 'icon'>)
      ),
    [portfolio]
  );

  const totalPercentToRender = useMemo(
    () =>
      getPercentages(
        rawTableData.reduce((res, { percentage }) => res + percentage, 0),
        {
          round: true,
        }
      ),
    [rawTableData]
  );

  const {
    loading: loadingTableData,
    error,
    value: tableData = [],
  } = useAsync(() => {
    const dataWithoutIcon = rawTableData.filter(
      ({ cost }) => minPortfolioItemCostToRender <= cost
    );
    return extendPortfolioTableDataItemWithIcon(dataWithoutIcon);
  }, [rawTableData]);

  const [fiats, cryptos] = useMemo(
    () =>
      tableData.reduce<[PortfolioTableRowData[], PortfolioTableRowData[]]>(
        (acc, data) =>
          data.isFiat
            ? [[...acc[0], data], acc[1]]
            : [acc[0], [...acc[1], data]],
        [[], []]
      ),
    [tableData]
  );

  const smallTokensData = useMemo(() => {
    const rawData = portfolio
      .filter(({ cost }) => cost < minPortfolioItemCostToRender)
      .reduce(
        (acc, { cost, usdCost, percentage }) => ({
          amount:
            acc.amount + (currency === PortfolioCurrency.Eur ? cost : usdCost),
          percent: acc.percent + percentage,
        }),
        { amount: 0, percent: 0 }
      );

    return rawData.amount >= 0.01
      ? {
          amount: getPrice(
            rawData.amount,
            currency === PortfolioCurrency.Usd
              ? {
                  currency: 'USD',
                }
              : undefined
          ),
          percent: getPercentages(rawData.percent),
        }
      : null;
  }, [currency, portfolio]);

  const portfolioTotalCost = useMemo(() => {
    const costAmount = (portfolio || [])
      .map(({ cost, usdCost }) =>
        currency === PortfolioCurrency.Eur ? cost : usdCost
      )
      .reduce((res, cost) => res + cost, 0);

    return getPrice(
      costAmount,
      currency !== PortfolioCurrency.Eur
        ? {
            currency: 'USD',
          }
        : undefined
    );
  }, [portfolio, currency]);

  const overlayPopup = useMemo(() => {
    const isFailed = () => {
      if (!exchange?.sentToRebalancing) {
        return false;
      }
      return [
        LastRebalanceReasonType.Failed,
        LastRebalanceReasonType.FailedInit,
        LastRebalanceReasonType.FailedReinit,
        LastRebalanceReasonType.FailedUserIsLocked,
        LastRebalanceReasonType.FailedTransactions,
      ].some((reason) => reason === exchange?.lastRebalanceReasonResponse);
    };

    const isPortfolioUpdating = () => {
      if (!exchange?.sentToRebalancing) {
        return false;
      }
      return [
        LastRebalanceReasonType.Starttrial,
        LastRebalanceReasonType.Scheduled,
        LastRebalanceReasonType.Fromdemo,
        LastRebalanceReasonType.Manual,
      ].some((reason) => reason === exchange?.lastRebalanceReasonRequest);
    };

    const isBuySellOrderInProgress = () => {
      if (!exchange?.sentToRebalancing) {
        return false;
      }
      return [
        LastRebalanceReasonType.Deposit,
        LastRebalanceReasonType.Liquidate,
      ].some((reason) => reason === exchange?.lastRebalanceReasonRequest);
    };

    switch (true) {
      case isFailed():
        return [
          LastRebalanceReasonType.Deposit,
          LastRebalanceReasonType.Liquidate,
        ].some((reason) => reason === exchange?.lastRebalanceReasonRequest) ? (
          <BuySellOrderFailedPopup />
        ) : (
          <RebalanceFailedPopup />
        );
      case isPortfolioUpdating():
        return <RebalanceInProgressPopup />;
      case isBuySellOrderInProgress():
        return <OrderInProgressPopup />;
      case exchange?.portfolioOutdated:
        return <NewRebalancePopup />;
      default:
        return null;
    }
  }, [exchange]);

  const scroll = useHorizontalDemoScroll(tableWrapperRef, {
    disabled: !!overlayPopup,
  });

  const renderTableRow = (data: PortfolioTableRowData, index: number) => (
    <PortfolioTableRow key={index} data={data} index={index} />
  );

  return (
    <Wrapper $overlayed={!!overlayPopup} {...props}>
      <TableWrapper ref={tableWrapperRef} scrollLeft={scroll}>
        {(() => {
          if (loadingTableData && isEmpty(tableData)) {
            return (
              <Spinner
                style={{
                  margin: 'auto',
                }}
              />
            );
          }

          if (error) {
            return (
              <Text
                style={{
                  width: '100%',
                }}
                align={'center'}
              >
                Error
              </Text>
            );
          }

          return (
            <Table>
              <TableHead>
                <DefTableRow>
                  <OrderCell />
                  <NameCell>
                    {t('DASHBOARD_PAGE__portfolioNameColumn')}
                  </NameCell>
                  <PriceCell>
                    {t('DASHBOARD_PAGE__portfolioPriceColumn')}
                  </PriceCell>
                  <ChangeCell>
                    {t('DASHBOARD_PAGE__portfolioChangeColumn')}
                  </ChangeCell>
                  <TokenAmountCell>
                    {t('DASHBOARD_PAGE__portfolioCoinsColumn')}
                  </TokenAmountCell>
                  <ValueCell>
                    {t('DASHBOARD_PAGE__portfolioValueColumn')}
                  </ValueCell>
                  <PercentageCell>
                    {t('DASHBOARD_PAGE__portfolioPercentageColumn')}
                  </PercentageCell>
                </DefTableRow>
              </TableHead>
              <TableBody>
                {cryptos.map(renderTableRow)}
                {fiats.map(renderTableRow)}
                {!!smallTokensData && (
                  <DefTableRow>
                    <OrderCell />
                    <NameCell>
                      {t('DASHBOARD_PAGE__portfolioSmallTokensSumTitle')}
                    </NameCell>
                    <TableCell colSpan={3} />
                    <ValueCell>{smallTokensData.amount}</ValueCell>
                    <PercentageCell>{smallTokensData.percent}</PercentageCell>
                  </DefTableRow>
                )}
              </TableBody>
              <TableFooter>
                <DefTableRow>
                  <OrderCell />
                  <NameCell>{t('DASHBOARD_PAGE__portfolioTotal')}</NameCell>
                  <TableCell colSpan={3} />
                  <ValueCell>{portfolioTotalCost}</ValueCell>
                  <PercentageCell>{totalPercentToRender}</PercentageCell>
                </DefTableRow>
              </TableFooter>
            </Table>
          );
        })()}
      </TableWrapper>
      {overlayPopup}
    </Wrapper>
  );
};

const Table = styled(DefTable)`
  min-width: 100%;
  width: auto;
  align-self: start;
  flex: 1 1 auto;
  table-layout: fixed;

  /*noinspection CssUnusedSymbol*/
  .MuiTableCell-root {
    border-bottom: none;
  }

  /*noinspection CssUnusedSymbol*/
  .MuiTableCell-footer {
    ${semiBoldText};
  }
`;

const Wrapper = styled.div<{
  // noinspection SpellCheckingInspection
  $overlayed: boolean;
}>`
  ${({ $overlayed }) =>
    $overlayed &&
    css`
      min-height: 340px;
      position: relative;

      ${Table} {
        pointer-events: none;
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.85);
      }
    `}
`;

const TableWrapper = styled(animated.div)`
  display: flex;
  overflow-x: auto;
`;

const TableHead = styled(DefTableHead)`
  /*noinspection CssUnusedSymbol*/
  .MuiTableCell-head {
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: none;
    ${semiBoldText};
  }
`;

const TableBody = styled(DefTableBody)``;

const popupStyles = css`
  width: 100%;
  max-width: 320px;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 10%;
  z-index: 3;
`;

const NewRebalancePopup = styled(DefNewRebalancePopup)`
  ${popupStyles};
`;

const RebalanceInProgressPopup = styled(DefRebalanceInProgressPopup)`
  ${popupStyles};
`;

const OrderInProgressPopup = styled(DefOrderInProgressPopup)`
  ${popupStyles};
`;

const RebalanceFailedPopup = styled(DefRebalanceFailedPopup)`
  ${popupStyles};
`;

const BuySellOrderFailedPopup = styled(DefBuySellOrderFailedPopup)`
  ${popupStyles};
`;

export { IndexPortfolioTable };
