/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useState, useMemo, useEffect, ComponentProps, useRef } from 'react';
import styled, { css } from 'styled-components';
import { isNil, debounce } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { colors } from '../../utils/colors';
import { highstockCreditsLink } from '../../utils/consts';
import { getPrice, getPriceDifference } from '../../utils/currency';
import { semiBoldText } from '../../utils/fonts';
import { getPercentages } from '../../utils/percentages';
import { minPortfolioItemCostToRender } from '../../utils/portfolio';
import { PortfolioCurrency, DashboardDataQuery } from '../../apollo';
import { getColor, getDownMedia, getUpMedia } from '../../styles';
import { usePortfolioCurrency } from '../../providers';
import { GraphIconSummary, PieIcon } from '../icons';
import { Text } from '../texts';
import { PortfolioTableRowData } from './PortfolioTableRow';

export type IndexPortfolioPieProps = {
  portfolio: DashboardDataQuery['getIndexPortfolio'];
  portfolioTotals: DashboardDataQuery['getPortfolioTotals'];
} & ComponentProps<typeof Wrapper>;

const IndexPortfolioPie = ({
  portfolio,
  portfolioTotals,
  ...props
}: IndexPortfolioPieProps) => {
  const { t } = useTranslation();
  const { currency } = usePortfolioCurrency();

  const pieChartRef = useRef<PieChartRefData>(null);
  const [wrapperRef, { width: wrapperWidth }] = useMeasure();

  const [totalTextFontSize, setTotalTextFontSize] =
    useState<Nullish<number>>(null);

  const { totalCost, change24Amount, change24Percent } = useMemo(() => {
    return currency === PortfolioCurrency.Eur
      ? {
          totalCost: portfolioTotals.eurCostTotal,
          change24Amount: portfolioTotals.eurCostChange24Total,
          change24Percent: portfolioTotals.eurCostChange24Percentage,
        }
      : {
          totalCost: portfolioTotals.usdCostTotal,
          change24Amount: portfolioTotals.usdCostChange24total,
          change24Percent: portfolioTotals.usdCostChange24Percentage,
        };
  }, [portfolioTotals, currency]);

  const totalCostRenderValue = useMemo(
    () =>
      getPrice(
        totalCost,
        currency !== PortfolioCurrency.Eur
          ? {
              currency: 'USD',
            }
          : undefined
      ),
    [totalCost, currency]
  );

  // Redraw pie for window resize event
  const redrawPie = useMemo(
    () =>
      debounce(() => {
        requestAnimationFrame(() => {
          pieChartRef.current?.chart.reflow();
        });
      }, 300),
    [pieChartRef]
  );

  const pieOptions = useMemo<Highcharts.Options>(
    () =>
      getPieOptions({
        currency,
      }),
    [currency]
  );

  const infoBlocksData = useMemo<
    Array<{
      data: {
        positiveValue: boolean;
        valueToRender: string;
      };
      icon: JSX.Element;
      infoText: string;
    }>
  >(
    () => [
      {
        data: {
          positiveValue: change24Amount > 0,
          valueToRender: getPriceDifference(
            change24Amount,
            currency !== PortfolioCurrency.Eur
              ? {
                  currency: 'USD',
                }
              : undefined
          ),
        },
        icon: <PieIcon />,
        infoText: 'DASHBOARD_PAGE__summaryChangeValue',
      },
      {
        data: {
          positiveValue: change24Percent > 0,
          valueToRender: getPercentages(change24Percent, { withSign: true }),
        },
        icon: <GraphIconSummary />,
        infoText: 'DASHBOARD_PAGE__summaryChangePercent',
      },
    ],
    [currency, change24Amount, change24Percent]
  );

  // Update pie data
  useEffect(() => {
    if (!pieChartRef.current) return;

    const portfolioPieData = portfolio
      .filter(({ cost }) => minPortfolioItemCostToRender <= cost)
      .map(
        ({
          cost,
          usdCost,
          currency: { title, piechartColor, ...restCurrency },
          ...rest
        }) =>
          ({
            y: currency === PortfolioCurrency.Eur ? cost : usdCost,
            name: title,
            color: piechartColor,
            tableData: {
              title,
              cost,
              usdCost,
              ...restCurrency,
              ...rest,
            },
          } as PortfolioPieData)
      );

    pieChartRef.current.chart.addSeries({
      name: 'Portfolio',
      type: 'pie',
      data: portfolioPieData,
      // innerSize: '52%',
      showInLegend: true,
      dataLabels: {
        enabled: false,
      },
    });
  }, [pieChartRef, portfolio, currency]);

  useEffect(() => {
    window.addEventListener('resize', redrawPie);

    return () => {
      window.removeEventListener('resize', redrawPie);
    };
  }, [redrawPie]);

  useEffect(() => {
    const tid = setTimeout(() => {
      setTotalTextFontSize(
        wrapperWidth ? Math.round(wrapperWidth * 0.09) : null
      );
    }, 300);
    return () => clearTimeout(tid);
  }, [wrapperWidth]);

  return (
    <Wrapper ref={wrapperRef} {...props}>
      <PieBlock>
        <HighchartsReact
          ref={pieChartRef}
          highcharts={Highcharts}
          options={pieOptions}
          updateArgs={[true, true, true]}
        />
        <TotalText component={'p'} variant={'h1'} $fontSize={totalTextFontSize}>
          {totalCostRenderValue}
        </TotalText>
      </PieBlock>
      <InfoBlocks>
        {infoBlocksData.map(({ data, icon, infoText }) => (
          <InfoBlock key={infoText}>
            <InfoBlockValue $positiveValue={data.positiveValue}>
              {data.valueToRender}
            </InfoBlockValue>
            <InfoBlockIcon>{icon}</InfoBlockIcon>
            <InfoBlockDesc>{t(infoText)}</InfoBlockDesc>
          </InfoBlock>
        ))}
      </InfoBlocks>
    </Wrapper>
  );
};

type PieChartRefData = {
  chart: Highcharts.Chart;
  container: React.RefObject<HTMLDivElement>;
};

type PortfolioPieData = {
  y: number;
  name: string;
  color: string;
  tableData: Omit<PortfolioTableRowData, 'icon'>;
};

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const PieBlock = styled.div`
  align-self: center;
  width: 70%;
  aspect-ratio: 1;
  position: relative;

  ${getDownMedia('sm')} {
    max-width: 90%;
  }
`;

const InfoBlocks = styled.div`
  margin-top: 20px;
  align-self: flex-start;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 8px 4px;
  align-items: center;
`;

const InfoBlock = styled.div`
  display: contents;
`;

const InfoBlockValue = styled(Text)<{ $positiveValue?: boolean }>`
  ${semiBoldText};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: 1.3125;
  white-space: nowrap;
  ${({ $positiveValue }) => {
    if (isNil($positiveValue)) return null;

    return css`
      color: ${$positiveValue ? colors.green : colors.red};
    `;
  }}

  ${getUpMedia('lg')} {
    font-size: ${({ theme: { typography } }) => typography.pxToRem(18)};
  }

  ${getUpMedia(1400)} {
    font-size: ${({ theme: { typography } }) => typography.pxToRem(20)};
  }
`;

const InfoBlockIcon = styled.div`
  margin: 0 10px;

  svg {
    width: 14px;
    height: 14px;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const InfoBlockDesc = styled(Text)`
  font-size: ${({ theme: { typography } }) => typography.pxToRem(19)};

  ${getUpMedia('lg')} {
    font-size: ${({ theme: { typography } }) => typography.pxToRem(17)};
  }

  ${getUpMedia(1400)} {
    font-size: ${({ theme: { typography } }) => typography.pxToRem(19)};
  }
`;

const TotalText = styled(Text)<{
  $fontSize: Nullish<number>;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${({ theme, $fontSize }) =>
    $fontSize ? `${$fontSize}px` : theme.typography.pxToRem(30)};
  color: ${getColor('blue2')};
`;

const getPieOptions = ({
  currency,
}: {
  currency: PortfolioCurrency;
}): Highcharts.Options => ({
  chart: {
    type: 'pie',
    height: '100%',
    margin: 0,
    backgroundColor: 'transparent',
    events: {
      load: function () {
        this.credits.element.onclick = function () {
          window.open(highstockCreditsLink, '_blank', 'noreferrer');
        };
      },
    },
  },
  title: {
    text: '',
    style: {
      display: 'none',
    },
  },
  subtitle: {
    text: '',
    style: {
      display: 'none',
    },
  },
  plotOptions: {
    pie: {
      innerSize: '75%',
      shadow: false,
      states: {
        hover: {
          brightness: 0,
        },
      },
    },
  },
  tooltip: {
    formatter: function () {
      const price = getPrice(
        this.y,
        currency !== PortfolioCurrency.Eur
          ? {
              currency: 'USD',
            }
          : undefined
      );

      const percent = getPercentages(
        // @ts-ignore
        this.point?.tableData.percentage
      );

      return `<b>${this.point.name}</b>: ${price}; ${percent}`;
    },
  },
  legend: {
    enabled: false,
  },
});

export { IndexPortfolioPie };
