/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

// #1588f9
const DownloadIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M26.2 16c1.9-4.3.7-7-.2-8.3-1.4-2-4-3.1-6.3-2.7-4 .8-5.6 3.4-5.6 9v4.6l-2.3-2.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4 4c.2.2.5.3.7.3.3 0 .5-.1.7-.3l4-4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L16 18.7V14c0-5.8 2-6.6 4-7 1.3-.2 3.2.3 4.3 1.8 1.3 1.9 1.1 4.7-.7 7.8-.2.3-.2.8.1 1.1s.6.5 1 .4c.2 0 1-.2 1.4-.2 2.2 0 3.9 1.8 3.9 4s-1.8 4-4 4L6.1 26C3.8 25.7 2 23.5 2 21s1.8-4.6 4.3-4.9l.9-.1c.4-.1.8-.5.8-1v-1.1c0-3.6 2.2-6.5 5.3-7 .5-.1.9-.6.8-1.1s-.6-.9-1.1-.8c-4 .6-6.9 4.4-6.9 9v.2c-3.4.5-6 3.5-6 6.9 0 3.5 2.5 6.5 6 7h20c3.3 0 6-2.7 6-6-.1-3.4-2.7-6-5.9-6.1z"
    />
  </Icon>
);

export { DownloadIcon };
