/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { Paper } from '@mui/material';

export const IndexTablePopup = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 26px 32px 36px;
  box-shadow: 0 0 11px 0 rgba(63, 73, 105, 0.1);
`;
