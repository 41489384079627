/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { getUpMedia } from '../../styles';
import { Text } from '../../components/texts';

export const BlockTitle = styled(Text).attrs({
  variant: 'h3',
})`
  margin-bottom: 8px;

  ${getUpMedia('sm')} {
    font-size: 1.46rem;
  }
`;
