/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text as DefText } from '../texts';

export type PrivatePortfolioTablePlaceholderProps =
  HTMLAttributes<HTMLDivElement>;

const PrivatePortfolioTablePlaceholder = (
  props: PrivatePortfolioTablePlaceholderProps
) => {
  const { t } = useTranslation();
  return (
    <div {...props}>
      <Text component={'h3'} variant={'h3'}>
        {t('PRIVATE_PORTFOLIO_TABLE_PLACEHOLDER__title')}
      </Text>
      <Text>{t('PRIVATE_PORTFOLIO_TABLE_PLACEHOLDER__desc1')}</Text>
      <Text>{t('PRIVATE_PORTFOLIO_TABLE_PLACEHOLDER__desc2')}</Text>
    </div>
  );
};

const Text = styled(DefText)`
  & + & {
    margin-top: 16px;
  }
`;

export { PrivatePortfolioTablePlaceholder };
