/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  useCallback,
  useEffect,
  useMemo,
  CSSProperties,
  FC,
  PropsWithChildren,
} from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'utils/colors';
import { Box, Drawer as DefDrawer } from '@mui/material';
import { useServerErrorVar } from '../utils/apollo-vars';
import { useURLPathNotifications, useChatBox } from 'hooks';
import {
  getUpMedia,
  desktopContentSidePaddings,
  fullScreenHeightStyles,
  mobileContentSidePaddings,
} from '../styles';
import {
  useDrawerContext,
  useModals,
  useReminder2FAContext,
  useExchange,
  usePayments,
} from '../providers';
import { AppMenu, ScrollToTop } from '../components';
import { AppBar as DefAppBar } from '../components/AppBar';

const LayoutWithAsideMenu: FC<PropsWithChildren> = ({ children }) => {
  useURLPathNotifications();
  useChatBox();
  const { dispatch: modalsDispatch } = useModals();
  const serverError = useServerErrorVar();
  const { exchangeWizardVisible } = useExchange();
  const { paymentModalOpened } = usePayments();
  const { mobileOpened, dispatch } = useDrawerContext();
  const { shouldBeShown, setShouldBeShown } = useReminder2FAContext();

  const toggleMobileMenu = useCallback(() => {
    dispatch({ type: 'toggleMobile' });
  }, [dispatch]);

  const modalsOpened = useMemo(
    () => exchangeWizardVisible || paymentModalOpened,
    [exchangeWizardVisible, paymentModalOpened]
  );

  // Open 2fa reminder modal if required
  useEffect(() => {
    if (!shouldBeShown) return;
    if (!serverError) {
      modalsDispatch({
        type: 'setModalContent',
        payload: {
          name: 'reminder2FA',
        },
      });
    }
    setShouldBeShown(false);
  }, [modalsDispatch, shouldBeShown, serverError, setShouldBeShown]);

  return (
    <Wrapper>
      <ScrollToTop />
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={mobileOpened}
          elevation={0}
          onClose={toggleMobileMenu}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            square: true,
          }}
        >
          <AppMenu />
        </Drawer>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Drawer variant="permanent" open>
          <AppMenu />
        </Drawer>
      </Box>
      <ContentWrapper strictHeight={modalsOpened}>
        <AppBar />
        {children}
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${fullScreenHeightStyles};
  background-color: ${colors.lightBlue};
  position: relative;
`;

const Drawer = styled(DefDrawer)`
  ${({
    theme: {
      breakpoints,
      mixins: { toolbar },
    },
  }) => {
    const tabletBreakpoint = breakpoints.down('md');
    const mobileToolbarHeight = (
      toolbar[breakpoints.down('md')] as CSSProperties
    ).height;

    // noinspection CssUnusedSymbol
    return css`
      width: 75%;

      ${getUpMedia('md')} {
        width: ${({ theme: { drawer } }) => drawer?.currentWidth}px;
        flex-shrink: 0;
        transition: ${({
          theme: {
            transitions: { duration, easing },
          },
        }) => `width ${duration.short}ms ${easing.easeInOut}`};
      }

      ${tabletBreakpoint} {
        width: 100%;
        margin-top: ${mobileToolbarHeight}px;
      }

      .MuiBackdrop-root {
        ${tabletBreakpoint} {
          top: ${mobileToolbarHeight}px;
        }
      }

      .MuiDrawer-paper {
        width: inherit;
        background-color: ${colors.darkBlue};
        height: 100%;
        box-shadow: none;

        ${tabletBreakpoint} {
          height: calc(100% - ${mobileToolbarHeight}px);
          top: ${mobileToolbarHeight}px;
        }
      }

      .MuiDrawer-paperAnchorDockedLeft {
        border-right: 0;
      }
    `;
  }}
`;

const AppBar = styled(DefAppBar)`
  box-shadow: none;

  ${getUpMedia('md')} {
    width: calc(100% - ${({ theme: { drawer } }) => drawer?.currentWidth}px);
    margin-left: ${({ theme: { drawer } }) => drawer?.currentWidth}px;
    transition: ${({
      theme: {
        transitions: { duration, easing },
      },
    }) => {
      const dur = duration.short;
      const ease = easing.easeInOut;
      return `width ${dur}ms ${ease}, margin-left ${dur}ms ${ease}`;
    }};
  }

  /*noinspection CssUnusedSymbol*/
  .MuiToolbar-regular {
    padding: 0 ${desktopContentSidePaddings}px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-left: ${mobileContentSidePaddings}px;
      padding-right: ${mobileContentSidePaddings}px;
    }
  }
`;

const ContentWrapper = styled.div<{
  strictHeight: boolean;
}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - ${({ theme: { drawer } }) => drawer?.currentWidth}px);
  margin-top: ${({
    theme: {
      mixins: {
        toolbar: { height },
      },
    },
  }) => height}px;
  position: relative;

  ${getUpMedia('md')} {
    transition: ${({
      theme: {
        transitions: { duration, easing },
      },
    }) => `width ${duration.short}ms ${easing.easeInOut}`};
  }

  ${({
    theme: {
      breakpoints,
      mixins: { toolbar },
    },
    strictHeight,
  }) => {
    const tabletBreakpoint = breakpoints.down('md');
    const mobileToolbarHeight = (
      toolbar[breakpoints.down('md')] as CSSProperties
    ).height;

    return css`
      ${tabletBreakpoint} {
        margin-top: ${mobileToolbarHeight ?? 0}px;
      }

      ${
        strictHeight &&
        css`
          height: calc(100vh - ${toolbar.height}px);

          ${tabletBreakpoint} {
            height: calc(100vh - ${mobileToolbarHeight}px);
          }
        `
      }}
    `;
  }}
`;

export { LayoutWithAsideMenu };
