/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { sendSentryError } from '../../utils/sentry';
import { useAdminDisableUsersTotpMutation } from '../../apollo';
import { useAlert, useModals } from '../../providers';
import { Button as DefButton } from '../buttons';

const Disable2FAAdminModal = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { data: { userId } = {}, dispatch } = useModals<'disable2FAAdmin'>();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'disable-2-fa-admin',
        titleText: t('DISABLE_2FA_ADMIN_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  const [disableTOTP, { loading: TOTPDisabling }] =
    useAdminDisableUsersTotpMutation({
      onCompleted: () => {
        setAlert({
          type: 'success',
          message: t('DISABLE_2FA_ADMIN_MODAL__success'),
        });
        dispatch({ type: 'closeModal' });
      },
      onError: (error) => {
        setAlert({
          type: 'error',
          message: t('DISABLE_2FA_ADMIN_MODAL__error'),
        });
        sendSentryError(error);
      },
    });

  return (
    <Button
      disabled={!userId || TOTPDisabling}
      onClick={() => {
        userId &&
          disableTOTP({
            variables: {
              userIDs: [userId],
            },
          });
      }}
    >
      {t('DISABLE_2FA_ADMIN_MODAL__button')}
    </Button>
  );
};

export type Disable2FAAdminModalData = {
  userId: number;
};

const Button = styled(DefButton)`
  && {
    width: 100%;
  }
  margin-top: 6px;
`;

export { Disable2FAAdminModal };
