/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { pxToRem } from '../../styles';
import { Text as DefText, TextProps } from '../texts';

export const Text = styled((props: TextProps) => (
  <DefText align={'center'} {...props} />
))`
  margin: 10px 0 0;
  font-size: ${pxToRem(16)};
`;
