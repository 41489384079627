/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@mui/material';
import { getPrice } from '../../utils/currency';
import { CustomUserStatus, Maybe } from '../../apollo';
import {
  getColor,
  getDownMedia,
  getUpMedia,
  resetListStyles,
} from '../../styles';
import { Text } from '../texts';

export type AdminUsersTableTopBarProps = Record<SummaryDataKey, number> & {
  customUserStatus: Maybe<CustomUserStatus>;
  setCustomUserStatus: (type: Maybe<CustomUserStatus>) => void;
};

const AdminUsersTableTopBar = ({
  customUserStatus,
  setCustomUserStatus,
  ...props
}: AdminUsersTableTopBarProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ButtonsList>
        {buttons.map(({ label, status }, index) => {
          const active = (() => {
            if (
              (!customUserStatus ||
                !buttons
                  .map(({ status }) => status)
                  .some((status) => customUserStatus === status)) &&
              index === 0
            ) {
              return true;
            }

            return status === customUserStatus;
          })();

          return (
            <ButtonsListItem key={label}>
              <Button
                $active={active}
                onClick={() => {
                  if (customUserStatus !== status) {
                    setCustomUserStatus(status);
                  }
                }}
              >
                {t(label)}
              </Button>
            </ButtonsListItem>
          );
        })}
      </ButtonsList>
      <SummaryList>
        {(
          Object.entries(summaryData) as Array<
            [SummaryDataKey, SummaryDataValue]
          >
        ).map(([key, { label, modifier }]) => (
          <SummaryListItem key={key}>
            <SummaryListText weight={'semiBold'}>
              {t(label)}: {modifier(props[key])}
            </SummaryListText>
          </SummaryListItem>
        ))}
      </SummaryList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid ${getColor('blueBayoux')};
`;

const ButtonsList = styled.ul`
  ${resetListStyles};
  display: flex;
  overflow-x: auto;

  ${getDownMedia('lg')} {
    flex: 0 0 100%;
    order: 1;
    margin-top: 5px;
  }
`;

const ButtonsListItem = styled.li`
  & + & {
    margin-left: 10px;
  }
`;

const Button = styled(ButtonBase)<{ $active: boolean }>`
  padding: 6px 8px 4px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${({
    $active,
    theme: {
      palette: {
        colors: { darkBlue },
        primary: { main: mainColor },
      },
    },
  }) => ($active ? darkBlue : mainColor)};
  color: #fff;
  white-space: nowrap;

  &:hover {
    background-color: ${getColor('darkBlue')};
  }

  ${getUpMedia(1440)} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const SummaryList = styled.ul`
  ${resetListStyles};
  display: flex;
`;

const SummaryListItem = styled.li`
  & + & {
    margin-left: 20px;
  }
`;

const SummaryListText = styled(Text)``;

const summaryData = {
  usersAmount: {
    label: 'ADMIN_ALL_USERS_PAGE__topBarUsersAmount',
    modifier: (value: number) => value,
  },
  totalAum: {
    label: 'ADMIN_ALL_USERS_PAGE__topBarTotalAum',
    modifier: (value: number) => getPrice(value),
  },
  totalCosts: {
    label: 'ADMIN_ALL_USERS_PAGE__topBarTotalCost',
    modifier: (value: number) => getPrice(value),
  },
  totalInvoices: {
    label: 'ADMIN_ALL_USERS_PAGE__topBarTotalInvoices',
    modifier: (value: number) => getPrice(value),
  },
} as const;

type SummaryDataKey = keyof typeof summaryData;
type SummaryDataValue = typeof summaryData[SummaryDataKey];

const buttons = [
  {
    label: 'ADMIN_ALL_USERS_PAGE__topBarAllStatus',
    status: undefined,
  },
  {
    label: 'ADMIN_ALL_USERS_PAGE__topBarTrialStatus',
    status: CustomUserStatus.Trial,
  },
  {
    label: 'ADMIN_ALL_USERS_PAGE__topBarActiveStatus',
    status: CustomUserStatus.Active,
  },
  {
    label: 'ADMIN_ALL_USERS_PAGE__topBarCanceledStatus',
    status: CustomUserStatus.Cancelled,
  },
  {
    label: 'ADMIN_ALL_USERS_PAGE__topBarRemovedStatus',
    status: CustomUserStatus.Removed,
  },
];

export { AdminUsersTableTopBar };
