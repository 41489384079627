/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useMemo, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { pick } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { Formik, Form as DefForm, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { sendSentryError } from 'utils/sentry';
import * as Yup from 'yup';
import { Link as DefLink } from '@mui/material';
import { serverResponseErrorsCodes } from '../../utils/consts';
import { semiBoldText } from '../../utils/fonts';
import { isServerError } from '../../utils/helpers';
import { getErrorCodesOfServerResponse } from '../../utils/server-helpers';
import { useSignIn } from 'hooks';
import { useSignInMutation } from 'apollo';
import { getColor } from '../../styles';
import {
  useAlert,
  useGoogleRecaptchaContext,
  useModals,
} from '../../providers';
import { GoogleRecaptchaV2 } from '../GoogleRecaptchaV2';
import { Button as DefButton } from '../buttons';
import {
  Input,
  PasswordField,
  CheckboxWithLabel as DefCheckbox,
} from '../form-elements';

export type LoginFormProps = HTMLAttributes<HTMLFormElement>;

const LoginForm = (props: LoginFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { dispatch } = useModals();
  const signInHandler = useSignIn();
  const recaptchaData = useGoogleRecaptchaContext();
  const fieldsDataByName = useMemo(() => getfieldsDataByName(t), [t]);

  useEffectOnce(() => {
    recaptchaData.initRecaptcha('loginForm');
  });

  const [signIn, { loading }] = useSignInMutation({
    fetchPolicy: 'no-cache',
    onCompleted: ({ signIn }) => {
      signInHandler({
        data: signIn,
        with2faReminder: true,
      });
    },
    onError: (error) => {
      const { recaptchaError } = recaptchaData.handleCaptchaError(error);
      if (recaptchaError) return;

      if (isServerError(error)) {
        setAlert({
          type: 'error',
          message: t('COMMON_FORMS__serverUnavailableError'),
        });
      } else {
        setAlert({ type: 'error', message: t('LOGIN_FORM__errorText') });
      }

      sendSentryError(error);
    },
  });

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        remember: false,
      }}
      validationSchema={LoginSchema(t)}
      onSubmit={async (values, { setFieldValue }) => {
        if (!recaptchaData.token) {
          setAlert({ type: 'error', message: t('LOGIN_FORM__errorText') });
          sendSentryError(new Error('No captchaToken in login form'));
          return;
        }

        const captchaFieldName =
          recaptchaData.version === 'V3' ? 'captchaToken' : 'captchaV2Token';

        const { errors } = await signIn({
          variables: {
            ...values,
            [captchaFieldName]: recaptchaData.token,
          },
        });

        const passwordError = getErrorCodesOfServerResponse(errors).some(
          (code) => code === serverResponseErrorsCodes.WRONG_PASSWORD
        );

        if (passwordError) {
          setFieldValue('password', '');
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        isSubmitting,
      }: FormikProps<FormValues>) => (
        <Form {...props}>
          {Object.entries(pick(values, ['email', 'password'])).map(
            ([name, value]) => {
              const Component =
                fieldsDataByName[name].type === 'password'
                  ? PasswordField
                  : FieldInput;

              return (
                <Component
                  key={name}
                  id={`login-form-${name}`}
                  type={fieldsDataByName[name].type || 'text'}
                  label={fieldsDataByName[name].placeholder || name}
                  name={name}
                  value={value}
                  fullWidth
                  disabled={isSubmitting}
                  error={touched[name] && !!errors[name]}
                  helperText={touched[name] && !!errors[name] && errors[name]}
                  onChange={handleChange}
                />
              );
            }
          )}
          <GoogleRecaptchaV2 />
          <BottomBlock>
            <Checkbox
              label={fieldsDataByName.remember.placeholder}
              name={'remember'}
              value={values.remember}
              onChange={handleChange}
            />
            <Button type={'submit'} loading={loading} disabled={loading}>
              {t('LOGIN_FORM__buttonText')}
            </Button>
            <Link
              onClick={() => {
                dispatch({
                  type: 'setModalContent',
                  payload: {
                    name: 'resetPassword',
                  },
                });
              }}
            >
              {t('LOGIN_FORM__forgotPasswordText')}
            </Link>
          </BottomBlock>
        </Form>
      )}
    </Formik>
  );
};

const Form = styled(DefForm)`
  display: flex;
  flex-wrap: wrap;
`;

const FieldInput = styled(Input)`
  flex: 0 0 100%;
`;

const Checkbox = styled(DefCheckbox)`
  /*noinspection CssUnusedSymbol*/
  .MuiFormControlLabel-label {
    color: ${getColor('boulder')};
  }
`;

const BottomBlock = styled.div`
  flex: 0 0 100%;
  padding-top: 3px;
`;

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 6px;
  margin-bottom: 20px;
`;

const Link = styled(DefLink)`
  cursor: pointer;
  ${semiBoldText};
`;

type FormValues = {
  email: string;
  password: string;
  remember: boolean;
};

const LoginSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('COMMON_FORMS_ERROR__wrongEmail'))
      .required(t('COMMON_FORMS_ERROR__required')),
    password: Yup.string()
      .min(8, t('COMMON_FORMS_ERROR__tooShort'))
      .max(50, t('COMMON_FORMS_ERROR__tooLong'))
      .required(t('COMMON_FORMS_ERROR__required')),
    remember: Yup.boolean(),
  });

const getfieldsDataByName: (t: TFunction) => {
  [key in keyof FormValues]: {
    placeholder: string;
    type: 'text' | 'password' | 'checkbox';
  };
} = (t) => ({
  email: {
    type: 'text',
    placeholder: t('COMMON_FORMS__emailFieldPlaceholder'),
  },
  password: {
    type: 'password',
    placeholder: t('COMMON_FORMS__passwordFieldPlaceholder'),
  },
  remember: {
    type: 'checkbox',
    placeholder: t('LOGIN_FORM__staySignedInPlaceholder'),
  },
});

export { LoginForm };
