/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import iconsManifest from 'cryptocurrency-icons/manifest.json';
import type { PortfolioTableRowData } from '../components/portfolio/PortfolioTableRow';
import { checkFileExistence } from './check-file-existence';

export const minPortfolioItemCostToRender = 5;

export const portfolioDataPollInterval = 30 * 1000;

export const getCryptoIconPath = async (rawSymbol: string) => {
  const symbol = rawSymbol.toLowerCase();

  const iconData = iconsManifest.find(
    ({ symbol: manifestSymbol }) =>
      manifestSymbol.toLowerCase() === symbol.toLowerCase()
  );

  if (iconData) {
    const iconName = iconData.symbol.toLowerCase();

    try {
      return (await import(`cryptocurrency-icons/svg/color/${iconName}.svg`))
        .default;
    } catch (e) {
      return (await import(`cryptocurrency-icons/svg/color/generic.svg`))
        .default;
    }
  }

  const defFolderSymbolUrl =
    process.env.PUBLIC_URL + `/assets/img/icons/${symbol}`;

  const imageInDefFolder = (
    await Promise.all(
      ['svg', 'png', 'jpg'].map((ext) =>
        checkFileExistence(`${defFolderSymbolUrl}.${ext}`)
      )
    )
  ).find((value) => !!value);

  if (imageInDefFolder) return imageInDefFolder;

  return (await import(`cryptocurrency-icons/svg/color/generic.svg`)).default;
};

export const extendPortfolioTableDataItemWithIcon = (
  tableData: Array<Omit<PortfolioTableRowData, 'icon'>>
): Promise<PortfolioTableRowData[]> => {
  try {
    return Promise.all(
      tableData.map(async ({ symbol, ...rest }) => ({
        ...rest,
        symbol,
        icon: await getCryptoIconPath(symbol),
      }))
    );
  } catch (e) {
    return Promise.reject(e);
  }
};

const stakedCurrenciesEndings = ['.s', '.p'];

export const isStakedCurrency = (symbol: string) =>
  stakedCurrenciesEndings.some((ending) =>
    symbol.toLowerCase().endsWith(ending)
  );
