/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useMemo, useEffect, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isEmpty, toNumber, uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { serverResponseErrorsCodes } from '../../utils/consts';
import { getCryptoIconPath } from '../../utils/portfolio';
import { sendSentryError } from '../../utils/sentry';
import { isServerErrorOfType } from '../../utils/server-helpers';
import { useAlertModal } from '../../hooks';
import {
  PortfolioPartitionAction,
  usePortfolioLiquidateMutation,
  useWithdrawCurrenciesOptionsQuery,
} from '../../apollo';
import { useAlert, useExchange } from '../../providers';
import { Spinner } from '../../styled';
import { BlockText, BlockTitle } from '../../styled/portfolio';
import {
  PortfolioManipulationForm,
  PortfolioManipulationFormProps,
} from '../forms';

type IndexPortfolioWithdrawProps = HTMLAttributes<HTMLDivElement>;

const IndexPortfolioWithdraw = (props: IndexPortfolioWithdrawProps) => {
  const { t } = useTranslation();

  const { exchange } = useExchange();
  const { setAlert } = useAlert();
  const { dispatch: dispatchAlertModal } = useAlertModal();

  const { loading, error, data } = useWithdrawCurrenciesOptionsQuery({
    fetchPolicy: 'no-cache',
    skip: !exchange?.exchangeID,
    pollInterval: 10 * 1000,
    variables: {
      exchangeId: toNumber(exchange?.exchangeID),
    },
  });

  const { loading: loadingCurrenciesOptions, value: currenciesOptions = [] } =
    useAsync(async () => {
      const [fiatOptions] = await Promise.all([
        Promise.all(
          (data?.getIndexPortfolioSortedForSale ?? []).map(
            async ({ currency: { id, symbol } }) => ({
              label: symbol,
              value: id,
              icon: await getCryptoIconPath(symbol),
            })
          )
        ),
      ]);
      return uniqBy([...fiatOptions], 'value');
    }, [data]);

  const formDisabled = useMemo(() => exchange?.sentToRebalancing, [exchange]);

  const [liquidate] = usePortfolioLiquidateMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      dispatchAlertModal(['setModalContent', 'pendingSellCrypto']);
    },
    onError: (error: any) => {
      let message;

      switch (true) {
        case isServerErrorOfType({
          error,
          type: serverResponseErrorsCodes.WITHDRAW_NOT_ENOUGH_LEFT_MONEY,
        }): {
          message = 'INDEX_PORTFOLIO_WITHDRAW__notEnoughLeftMoneyErrorText';
          break;
        }
        case isServerErrorOfType({
          error,
          type: serverResponseErrorsCodes.WITHDRAW_NO_SUCH_CURRENCY_IN_INDEX_PORTFOLIO,
        }): {
          message = 'INDEX_PORTFOLIO_WITHDRAW__noSuchCurrencyErrorText';
          break;
        }
        case isServerErrorOfType({
          error,
          type: serverResponseErrorsCodes.WITHDRAW_NOT_ENOUGH_MONEY_TO_WITHDRAW,
        }): {
          message =
            'INDEX_PORTFOLIO_WITHDRAW__notEnoughMoneyToWithdrawErrorText';
          break;
        }
        default:
          message = 'INDEX_PORTFOLIO_WITHDRAW__errorText';
      }

      setAlert({ type: 'error', message: t(message) });

      if (message === 'INDEX_PORTFOLIO_WITHDRAW__errorText') {
        sendSentryError(error);
      }
    },
  });

  useEffect(() => {
    const noExchangeIdError = !exchange?.exchangeID
      ? new Error('No exchange id error in IndexPortfolio withdraw')
      : undefined;
    const finalError = noExchangeIdError || error;

    if (finalError) {
      sendSentryError(finalError);
    }
  }, [exchange?.exchangeID, error]);

  const onSubmit: PortfolioManipulationFormProps['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      if (!exchange?.exchangeID) {
        throw new Error('No exchange id in withdraw mutation');
      }

      await liquidate({
        variables: {
          exchangeId: toNumber(exchange.exchangeID),
          currencyId: toNumber(values.currencyId),
          percent: parseFloat(values.percent),
        },
      });

      resetForm();
    } catch (e) {
      sendSentryError(e);
      setAlert({
        type: 'error',
        message: t('COMMON_ERROR'),
      });
    }
  };

  return (
    <Wrapper {...props}>
      <BlockTitle>{t('INDEX_PORTFOLIO_WITHDRAW__title')}</BlockTitle>
      <BlockText>{t('INDEX_PORTFOLIO_WITHDRAW__text1')}</BlockText>
      <BlockText>{t('INDEX_PORTFOLIO_WITHDRAW__text2')}</BlockText>
      <LastDescText>{t('INDEX_PORTFOLIO_WITHDRAW__text3')}</LastDescText>
      {(loading || loadingCurrenciesOptions) && isEmpty(currenciesOptions) ? (
        <Spinner
          style={{
            margin: '20px auto',
          }}
        />
      ) : (
        <FormWrapper>
          <LastDescText weight={'semiBold'}>
            {t('INDEX_PORTFOLIO_WITHDRAW__formTitle')}
          </LastDescText>
          <PortfolioManipulationForm
            portfolioManipulationAction={PortfolioPartitionAction.Withdraw}
            options={currenciesOptions}
            inputsPrefix={'withdraw'}
            disabled={formDisabled}
            buttonText={'INDEX_PORTFOLIO_WITHDRAW__formButton'}
            onSubmit={onSubmit}
          />
        </FormWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  margin-top: auto;
`;

const LastDescText = styled(BlockText)`
  margin-bottom: 16px;
`;

export { IndexPortfolioWithdraw };
