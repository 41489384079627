/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { Icon, IconProps } from './Icon';

const BubbleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 19.2 17" {...props}>
    <path
      fill="currentColor"
      d="M.6 17c-.2 0-.4-.1-.5-.4s0-.4.2-.6c1.7-1 2.4-2.5 2.6-3.2C1.1 11.4 0 9.4 0 7.4c0-1 .3-2 .8-3C1.3 3.5 2 2.7 2.9 2 4.7.8 7.1 0 9.6 0s4.9.8 6.7 2.2c.9.7 1.6 1.5 2.1 2.4s.8 1.9.8 3-.3 2-.8 3c-.5.9-1.2 1.7-2.1 2.4-1.8 1.2-4.2 2-6.7 2-1 0-2-.1-3-.4-.4.3-1.2.8-2.2 1.3-1.5.7-2.8 1.1-3.8 1.1zm9-16C4.9 1 1.1 3.9 1.1 7.5c0 1.8 1 3.6 2.8 4.8.2.1.2.3.2.5-.1.4-.5 1.6-1.6 2.8 1.3-.5 2.7-1.2 3.7-2 .1-.1.3-.1.4-.1 1 .4 2 .5 3 .5 4.7 0 8.5-2.9 8.5-6.5S14.3 1 9.6 1z"
    />
  </Icon>
);

export { BubbleIcon };
