/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useState, useEffect, useCallback } from 'react';
import { isEmpty, uniqBy } from 'lodash-es';
import { ApolloClient } from '@apollo/client';
import { sendSentryError } from '../utils/sentry';
import {
  NotificationType,
  useUserNotificationsQuery,
  UserExchangesDocument,
  UserNotificationsQueryHookResult,
} from '../apollo';
import { useAlertModal, AlertModalContentType } from './useAlertModal';
import { useWindowTabIsActive } from './useWindowTabIsActive';

export const useUserNotifications = () => {
  const { modalOpened, dispatch: dispatchAlertModal } = useAlertModal();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const tabIsActive = useWindowTabIsActive();

  const { client, data, refetch, startPolling, stopPolling } =
    useUserNotificationsQuery({
      fetchPolicy: 'no-cache',
      pollInterval,
    });

  const displayNotification = useCallback(() => {
    const notificationsCopy = [...notifications];
    const notification = notificationsCopy.shift();
    if (!notification) return;
    dispatchAlertModal([
      'setModalContent',
      notificationsModals[notification.type].alertName,
    ]);
    notificationsModals[notification.type].extraAction?.({
      client,
    });
    setNotifications(notificationsCopy);
  }, [client, notifications, dispatchAlertModal]);

  // Saving notifications to state
  useEffect(() => {
    if (data?.getUserNotifications) {
      setNotifications((prevNotifications) =>
        uniqBy([...prevNotifications, ...data.getUserNotifications], 'type')
      );
    }
  }, [data]);

  // Displaying notifications in queue
  useEffect(() => {
    if (!modalOpened && !isEmpty(notifications)) {
      displayNotification();
    }
  }, [modalOpened, notifications, displayNotification]);

  useEffect(() => {
    if (tabIsActive) {
      refetch();
      startPolling(pollInterval);
    } else {
      stopPolling();
    }
  }, [tabIsActive, refetch, startPolling, stopPolling]);
};

type Notification = NonNullable<
  UserNotificationsQueryHookResult['data']
>['getUserNotifications'][number];

/*
 * If there will be no another notifications,
 * consider to add extraAction: updateExchangesAndPortfolioFn
 * with one line above
 * */
const notificationsModals: {
  [key in NotificationType]: {
    alertName: AlertModalContentType;
    extraAction?: (data: { client: ApolloClient<any> }) => unknown;
  };
} = {
  [NotificationType.PortfolioUpdateInProgress]: {
    alertName: 'rebalanceInProgress',
    extraAction: updateExchangesAndPortfolioFn,
  },
  [NotificationType.PortfolioUpdateFinished]: {
    alertName: 'rebalanceFinalised',
    extraAction: updateExchangesAndPortfolioFn,
  },
  [NotificationType.PortfolioUpdateFailed]: {
    alertName: 'rebalanceFailed',
    extraAction: updateExchangesAndPortfolioFn,
  },
  [NotificationType.BuySellInProgress]: {
    alertName: 'buySellOrderInProgress',
    extraAction: updateExchangesAndPortfolioFn,
  },
  [NotificationType.BuySellFinished]: {
    alertName: 'buySellOrderFinalised',
    extraAction: updateExchangesAndPortfolioFn,
  },
  [NotificationType.BuySellFailed]: {
    alertName: 'buySellOrderFailed',
    extraAction: updateExchangesAndPortfolioFn,
  },
  [NotificationType.UserExchangeLockedKraken]: {
    alertName: 'krakenExchangeLocked',
    extraAction: updateExchangesAndPortfolioFn,
  },
  [NotificationType.UserExchangeLockedBitvavo]: {
    alertName: 'bitvavoExchangeLocked',
    extraAction: updateExchangesAndPortfolioFn,
  },
};

function updateExchangesAndPortfolioFn({
  client,
}: {
  client: ApolloClient<any>;
}) {
  return Promise.all([
    client.query({
      query: UserExchangesDocument,
      fetchPolicy: 'network-only',
    }),
    client.refetchQueries({
      include: ['dashboardData'],
    }),
  ]).catch((e) => {
    sendSentryError(e);
  });
}

const pollInterval = 5000;
