/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '../../utils/colors';
import { sendSentryError } from '../../utils/sentry';
import { useRemoveAccountMutation } from '../../apollo';
import { useAlert, useModals, useAlertModal } from '../../providers';
import { Button as DefButton } from '../buttons';
import { Text as DefText } from '../texts';

const RemoveAccountModal = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { dispatch: dispatchModal } = useModals();
  const { dispatch: dispatchAlertModal } = useAlertModal();

  useEffect(() => {
    dispatchModal({
      type: 'setWrapperProps',
      payload: {
        modalName: 'remove-account',
        titleText: t('REMOVE_ACCOUNT_MODAL__title'),
        titleLineColor: 'orange',
      },
    });
  }, [dispatchModal, t]);

  const [removeAccount, { loading: removingAccount }] =
    useRemoveAccountMutation({
      fetchPolicy: 'no-cache',
      onCompleted: ({ removeUser: { success } }) => {
        if (!success) {
          setAlert({
            type: 'warning',
            message: t('REMOVE_ACCOUNT_MODAL__error'),
          });
          sendSentryError(new Error(t('REMOVE_ACCOUNT_MODAL__error')));
          return;
        }
        dispatchModal({ type: 'closeModal' });
        dispatchAlertModal([
          'setModalContent',
          'accountRemovementEmailConfirm',
        ]);
      },
      onError: (error) => {
        if ((error.toString() || '').substring(22) === `Wrong password`) {
          setAlert({
            type: 'error',
            message: t('REMOVE_ACCOUNT_MODAL__wrongPassword'),
          });
          return;
        }

        setAlert({ type: 'error', message: t('REMOVE_ACCOUNT_MODAL__error') });
        sendSentryError(error);
      },
    });

  return (
    <>
      <Text>{t('REMOVE_ACCOUNT_MODAL__desc')}</Text>
      <Button
        loading={removingAccount}
        buttonStyles={css`
          &:hover {
            background-color: ${colors.orange};
          }
        `}
        onClick={() => removeAccount()}
      >
        {t('REMOVE_ACCOUNT_MODAL__removeButtonText')}
      </Button>
    </>
  );
};

const Text = styled(DefText)`
  margin-bottom: 30px;
  line-height: 19px;
`;

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 6px;
`;

export { RemoveAccountModal };
