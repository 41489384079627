/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo, HTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import { omit, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { FileCopyOutlined } from '@mui/icons-material';
import { Link, InputAdornment, IconButton } from '@mui/material';
import { externalLinkProps, SiteData } from '../../utils/consts';
import { getClipboardText, delay } from '../../utils/helpers';
import { sendSentryError } from '../../utils/sentry';
import {
  useAddExchangeKeysMutation,
  ExchangeCode,
  WizardStatusType,
} from '../../apollo';
import { getColor } from '../../styles';
import { useAlert, useExchangeWizard, useExchange } from '../../providers';
import { Button as DefButton } from '../buttons';
import {
  FormikCheckboxWithLabel,
  FormikInput,
  FormikCheckboxWithLabelProps,
  FormikInputProps,
} from '../formik-elements';

export type WizardExchangeKeysFormProps = HTMLAttributes<HTMLFormElement>;

const WizardExchangeKeysForm: FC<WizardExchangeKeysFormProps> = (props) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { exchange } = useExchange();
  const { dispatch } = useExchangeWizard();
  const [addKeys] = useAddExchangeKeysMutation();

  const schema = useMemo(() => getValidationSchema(t), [t]);
  const fields = useMemo(
    () => getDataByFieldName(t, exchange?.exchangeCode),
    [t, exchange]
  );

  if (!exchange) return null;

  return (
    <Formik
      initialValues={{
        key: '',
        secretKey: '',
        terms: false,
      }}
      validationSchema={schema}
      onSubmit={async (values) => {
        try {
          const { data } = await addKeys({
            variables: {
              exchangeId: toNumber(exchange?.exchangeID),
              ...omit(values, ['terms']),
            },
          });

          if (!data?.addKeys) {
            throw new Error('No data in addKeys mutation response');
          }

          const { wizardStatus, moneyUnderManagement, onlyFiatInPortfolio } =
            data.addKeys;

          // Required for backend to give currencies on next step
          await delay(1500);

          dispatch({ type: 'goToNextStep' });

          dispatch({
            type: 'setMoneyStatus',
            payload: {
              moneyExist: wizardStatus !== WizardStatusType.NoMoney,
              moneyAmount: moneyUnderManagement,
              fiatOnly: onlyFiatInPortfolio,
            },
          });

          setAlert({
            type: 'success',
            message: t('WIZARD_EXCHANGE_KEYS_FORM__successText'),
          });
        } catch (e) {
          setAlert({
            type: 'error',
            message: t('WIZARD_EXCHANGE_KEYS_FORM__errorText'),
          });
          sendSentryError(e);
        }
      }}
    >
      {({ values, setFieldValue, isSubmitting }: FormikProps<FormValues>) => (
        <Wrapper {...props}>
          {Object.entries(fields).map(([name, data]) => {
            const { Component = Input, ...props } = data;

            return (
              // @ts-ignore
              <Component
                key={name}
                id={`${exchange.exchangeCode}-form-${name}`}
                name={name}
                disabled={isSubmitting}
                {...(name !== 'terms'
                  ? {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={async () => {
                                const value = await getClipboardText();
                                setFieldValue(name, value);
                              }}
                            >
                              <CopyIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }
                  : {})}
                {...props}
              />
            );
          })}
          <Button
            type={'submit'}
            loading={isSubmitting}
            disabled={!values.terms || isSubmitting}
          >
            {t('WIZARD_EXCHANGE_KEYS_FORM__buttonText')}
          </Button>
        </Wrapper>
      )}
    </Formik>
  );
};

type FormValues = {
  key: string;
  secretKey: string;
  terms: boolean;
};

// Old validation for kraken
/*const getValidationSchema = (t: TFunction): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    key: Yup.string()
      .length(56, t('COMMON_FORMS_ERROR__wrongKeyLength'))
      .required(t('COMMON_FORMS_ERROR__required')),
    secretKey: Yup.string()
      .length(88, t('COMMON_FORMS_ERROR__wrongKeyLength'))
      .required(t('COMMON_FORMS_ERROR__required')),
    terms: Yup.bool().required(t('COMMON_FORMS_ERROR__required')),
  });*/

const getValidationSchema = (t: TFunction): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    key: Yup.string()
      .min(20, t('COMMON_FORMS_ERROR__wrongKeyLength'))
      .required(t('COMMON_FORMS_ERROR__required')),
    secretKey: Yup.string()
      .min(20, t('COMMON_FORMS_ERROR__wrongKeyLength'))
      .required(t('COMMON_FORMS_ERROR__required')),
    terms: Yup.bool().required(t('COMMON_FORMS_ERROR__required')),
  });

const Wrapper = styled(Form)`
  display: flex;
  flex-wrap: wrap;
`;

const Input = styled(FormikInput)`
  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }
`;

const CopyIcon = styled(FileCopyOutlined)`
  font-size: 1.2rem;
  color: ${getColor('blue')};
`;

const Checkbox = styled(FormikCheckboxWithLabel)`
  margin-top: 8px;
  .MuiFormControlLabel-label {
    font-size: 15px;
  }
`;

const Button = styled(DefButton)`
  margin: 20px 0 0;
  flex-basis: 100%;
`;

const generalInputProps: Omit<FormikInputProps, 'name'> = {
  InputLabelProps: {
    shrink: true,
  },
  autoComplete: 'off',
};

const translations: {
  [key in ExchangeCode]: {
    secretKey: {
      label: string;
      placeholder: string;
    };
    terms: string;
  };
} = {
  [ExchangeCode.Bitvavo]: {
    secretKey: {
      label: 'WIZARD_EXCHANGE_KEYS_FORM__bitvavoPrivateKeyFieldLabel',
      placeholder:
        'WIZARD_EXCHANGE_KEYS_FORM__bitvavoPrivateKeyFieldPlaceholder',
    },
    terms: 'WIZARD_EXCHANGE_KEYS_FORM__bitvavoTermsFieldPlaceholder',
  },
  [ExchangeCode.Kraken]: {
    secretKey: {
      label: 'WIZARD_EXCHANGE_KEYS_FORM__krakenPrivateKeyFieldLabel',
      placeholder:
        'WIZARD_EXCHANGE_KEYS_FORM__krakenPrivateKeyFieldPlaceholder',
    },
    terms: 'WIZARD_EXCHANGE_KEYS_FORM__krakenTermsFieldPlaceholder',
  },
};

const getDataByFieldName: (
  t: TFunction,
  exchange: ExchangeCode | undefined
) => {
  [key in keyof FormValues]: {
    Component?: typeof FormikCheckboxWithLabel;
  } & Omit<FormikInputProps | FormikCheckboxWithLabelProps, 'name'>;
} = (t, exchange) => ({
  key: {
    ...generalInputProps,
    label: t<string>('WIZARD_EXCHANGE_KEYS_FORM__keyFieldLabel'),
    placeholder: t('WIZARD_EXCHANGE_KEYS_FORM__keyFieldLabelPlaceholder'),
  },
  secretKey: {
    ...generalInputProps,
    ...(exchange
      ? {
          label: t<string>(translations[exchange].secretKey.label),
          placeholder: t(translations[exchange].secretKey.placeholder),
        }
      : {}),
  },
  terms: {
    Component: Checkbox,
    label: (
      <span>
        <>
          {exchange ? t(translations[exchange].terms) : ''}{' '}
          <Link href={SiteData.TERMS_LINK} {...externalLinkProps}>
            {t<string>('WIZARD_EXCHANGE_KEYS_FORM__termsFieldPlaceholderLink')}
          </Link>
          .
        </>
      </span>
    ),
  },
});

export { WizardExchangeKeysForm };
