/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo, HTMLAttributes } from 'react';
import { useSpring } from '@react-spring/web';
import { useCreateMollieClient } from '../hooks';
import { usePayments, PaymentModalTypes } from '../providers';
import {
  UnpaidInvoiceModal,
  TrialCreditCardModal,
  UnpaidInvoiceModalProps,
} from './content-modals';

const CreditCard = (props: HTMLAttributes<HTMLDivElement>) => {
  const { paymentModalType, paymentModalOpened, togglePaymentModal } =
    usePayments();

  const animation = useSpring({
    to: {
      opacity: paymentModalOpened ? 1 : 0,
    },
  });

  const [createNewMollieClient, { loading }] = useCreateMollieClient();

  const commonModalProps = useMemo<UnpaidInvoiceModalProps>(
    () => ({
      ...props,
      opened: paymentModalOpened,
      animation: animation as any,
      close: () => togglePaymentModal(false),
      buttonProps: {
        loading,
        onClick: createNewMollieClient,
      },
    }),
    [
      props,
      animation,
      createNewMollieClient,
      paymentModalOpened,
      loading,
      togglePaymentModal,
    ]
  );

  switch (paymentModalType) {
    case PaymentModalTypes.HAS_UNPAID_INVOICE:
      return <UnpaidInvoiceModal {...commonModalProps} />;
    case PaymentModalTypes.TRIAL_ENDED_AND_NO_CREDIT_CARD:
      return (
        <TrialCreditCardModal type={'trial-ended'} {...commonModalProps} />
      );
    default:
      return <TrialCreditCardModal type={'in-trial'} {...commonModalProps} />;
  }
};

export { CreditCard };
