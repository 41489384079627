/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { isNumber } from 'lodash-es';
import { TableRowProps } from '@mui/material';
import { externalLinkProps } from '../../utils/consts';
import { getPrice } from '../../utils/currency';
import { getPercentages } from '../../utils/percentages';
import { useTruncateText } from '../../hooks';
import {
  PortfolioCurrency,
  ExpectedPortfolioTokenStatus,
  PortfolioRowResponse,
  ExpectedPortfolioRowResponse,
} from '../../apollo';
import { getColor } from '../../styles';
import { usePortfolioCurrency } from '../../providers';
import {
  ChangeCell,
  NameCell,
  OrderCell,
  PercentageCell,
  PriceCell,
  TableRow,
  TokenAmountCell,
  ValueCell,
} from '../../styled/portfolio';
import { Text } from '../texts';

export type PortfolioTableRowProps = {
  data: PortfolioTableRowData;
  index: number;
  withFiatIndex?: boolean;
} & TableRowProps;

const PortfolioTableRow = ({
  data,
  index,
  withFiatIndex = false,
  ...props
}: PortfolioTableRowProps) => {
  const truncateText = useTruncateText();
  const { currency } = usePortfolioCurrency();

  const orderCellValue = useMemo(() => {
    if (data?.status === ExpectedPortfolioTokenStatus.Removed) {
      return '-/-';
    }

    return withFiatIndex || !data.isFiat ? `${index + 1}.` : '';
  }, [data.isFiat, data.status, index, withFiatIndex]);

  const { nameText, price, changePercent, amountCoins, value, percentage } =
    useMemo(() => {
      const nameText = <span>{truncateText(data.title)}</span>;

      const exists = data?.status !== ExpectedPortfolioTokenStatus.Removed;
      const euro = currency === PortfolioCurrency.Eur;
      const getPriceFnCurrency = !euro
        ? {
            currency: 'USD',
          }
        : undefined;

      const price = getPrice(
        euro ? data.eurValue : data.usdValue,
        getPriceFnCurrency,
        false
      );

      const changePercent = getPercentages(
        exists
          ? euro
            ? data.change24Percentage
            : data.usdChange24Percentage
          : 0,
        {
          withSign: true,
        }
      );

      const amountCoins = (exists ? data.amountCoins : 0).toLocaleString(
        'nl-NL'
      );

      const value = getPrice(
        exists ? (euro ? data.cost : data.usdCost) : 0,
        getPriceFnCurrency
      );

      const percentage = getPercentages(exists ? data.percentage : 0);

      return {
        nameText,
        price,
        changePercent,
        amountCoins,
        value,
        percentage,
      };
    }, [currency, data, truncateText]);

  const positiveChange = useMemo(() => {
    const exists = data?.status !== ExpectedPortfolioTokenStatus.Removed;
    return exists &&
      isNumber(data.change24Percentage) &&
      data.change24Percentage !== 0
      ? data.change24Percentage > 0
      : undefined;
  }, [data?.status, data.change24Percentage]);

  return (
    <TableRow $status={data?.status} {...props}>
      <OrderCell>{orderCellValue}</OrderCell>
      <NameCell colSpan={data.isFiat ? 4 : 0}>
        <div>
          <NameCellIconImg src={data.icon} />
          {data.isFiat ? (
            <NameCellText>{nameText}</NameCellText>
          ) : (
            <NameCellLink as={'a'} href={data.messari} {...externalLinkProps}>
              {nameText}
              <br />
              <NameCellTicker
                $colorInherit={
                  data.status !== ExpectedPortfolioTokenStatus.Existing
                }
              >
                {data.symbol.toUpperCase()}
              </NameCellTicker>
            </NameCellLink>
          )}
        </div>
      </NameCell>
      {!data.isFiat && (
        <>
          <PriceCell>{price}</PriceCell>
          <ChangeCell $positiveChange={positiveChange}>
            {changePercent}
          </ChangeCell>
          <TokenAmountCell>{amountCoins}</TokenAmountCell>
        </>
      )}
      <ValueCell>{value}</ValueCell>
      <PercentageCell>{percentage}</PercentageCell>
    </TableRow>
  );
};

export type PortfolioTableRowData = Pick<
  PortfolioRowResponse['currency'],
  'id' | 'symbol' | 'title' | 'isFiat' | 'eurValue' | 'usdValue' | 'messari'
> &
  Pick<
    PortfolioRowResponse,
    | 'change24Percentage'
    | 'usdChange24Percentage'
    | 'cost'
    | 'usdCost'
    | 'percentage'
    | 'amountCoins'
  > & {
    icon: string;
  } & Partial<Pick<ExpectedPortfolioRowResponse, 'status'>>;

const NameCellIconImg = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 10px;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
`;

const NameCellText = styled(Text)`
  font-size: 15px;
  line-height: 1;
  white-space: nowrap;
  color: inherit;
`;

const NameCellLink = styled(NameCellText)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const NameCellTicker = styled.span<{
  $colorInherit: boolean;
}>`
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  color: ${({ $colorInherit }) =>
    $colorInherit ? 'inherit' : getColor('veniceBlue')};
`;

export { PortfolioTableRow };
