/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { IndexTablePopup } from '../../styled/portfolio';
import { Icon } from '../alert-modals/Icon';
import { Text } from '../alert-modals/Text';
import { Title } from '../alert-modals/Title';

export type RebalanceFailedPopupProps = ComponentProps<typeof IndexTablePopup>;

const RebalanceFailedPopup = (props: RebalanceFailedPopupProps) => {
  const { t } = useTranslation();

  return (
    <IndexTablePopup square={false} {...props}>
      <Icon type={'sadFace'} />
      <Title>{t('INDEX_PORTFOLIO_TABLE_REBALANCE_FAILED_POPUP__title')}</Title>
      <Text>{t('INDEX_PORTFOLIO_TABLE_REBALANCE_FAILED_POPUP__desc')}</Text>
    </IndexTablePopup>
  );
};

export { RebalanceFailedPopup };
