/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Form as DefForm, Formik, FormikFormProps, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { Link, MenuItem as DefMenuItem } from '@mui/material';
import {
  investOptions,
  serverResponseErrorsCodes,
  SiteData,
} from '../../utils/consts';
import { sendSentryError } from '../../utils/sentry';
import { isServerErrorOfType } from '../../utils/server-helpers';
import { getInvalidProfileFormSchema } from '../../utils/validation-schemas';
import {
  useUserProfileQuery,
  useEditUserProfileMutation,
  EditUserProfileMutationVariables,
} from '../../apollo';
import { useAlert } from '../../providers';
import { Button as DefButton } from '../buttons';
import {
  FormikCheckboxWithLabel,
  FormikCountriesSelect,
  FormikInput,
  FormikInputProps,
} from '../formik-elements';
import { SmallInputPlaceholderText, Title as DefTitle } from '../texts';

export type InvalidProfileFormProps = FormikFormProps;

const InvalidProfileForm = (props: InvalidProfileFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const schema = useMemo(() => getInvalidProfileFormSchema(t), [t]);
  const dataByFieldName = useMemo(() => getDataByFieldName(t), [t]);

  const { data } = useUserProfileQuery({
    fetchPolicy: 'cache-and-network',
  });

  const user = data?.getUser;

  const initialValues = useMemo(() => {
    if (!user) return defInitialValues;
    return Object.entries(defInitialValues).reduce(
      (acc, [name, defValue]) => ({
        ...acc,
        [name]: user[name] ?? defValue,
      }),
      {} as InvalidProfileFormValues
    );
  }, [user]);

  const [editUser] = useEditUserProfileMutation({
    onCompleted: () => {
      setAlert({
        type: 'success',
        message: t('USER_PROFILE_FORM__successText'),
      });
    },
    onError: (error) => {
      if (
        isServerErrorOfType({
          error,
          type: serverResponseErrorsCodes.REFERAL_NOT_FOUND,
        })
      ) {
        return setAlert({
          type: 'error',
          message: t('USER_PROFILE_FORM__referralCodeErrorText'),
        });
      }

      setAlert({
        type: 'error',
        message: t('USER_PROFILE_FORM__errorText'),
      });

      sendSentryError(error);
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async (values) => {
        if (!isEqual(initialValues, values)) {
          await editUser({ variables: values });
        } else {
          setAlert({
            type: 'info',
            message: 'Same values',
          });
        }
      }}
    >
      {({ values, isSubmitting }: FormikProps<InvalidProfileFormValues>) => (
        <Form {...props}>
          <Title component={'h1'} variant={'h2'} withLine>
            {t('INVALID_PROFILE_FORM__title')}
          </Title>
          {(Object.keys(values) as Array<keyof InvalidProfileFormValues>).map(
            (name) => {
              const {
                Component = HalfWidthInput,
                type,
                extraProps,
              } = dataByFieldName[name];

              return (
                // @ts-ignore
                <Component
                  key={name}
                  id={`login-form-${name}`}
                  type={type || 'text'}
                  name={name}
                  disabled={isSubmitting}
                  {...extraProps}
                />
              );
            }
          )}
          <BottomBlock>
            <Button type={'submit'} loading={isSubmitting}>
              {t('INVALID_PROFILE_FORM__button')}
            </Button>
          </BottomBlock>
        </Form>
      )}
    </Formik>
  );
};

export type InvalidProfileFormValues = EditUserProfileMutationVariables;

// const FullWidthInput = styled(FormikInput)``;

const HalfWidthInput = styled(FormikInput)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-basis: calc(50% - 5px);
  }
`;

const CountriesSelect = styled(FormikCountriesSelect)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-basis: calc(50% - 5px);
  }
`;

// noinspection CssUnusedSymbol
const Checkbox = styled(FormikCheckboxWithLabel)`
  flex: 0 0 100%;
  margin-top: 8px;

  /*noinspection CssUnusedSymbol*/
  .Mui-error {
    font-size: 14px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-column: span 2;
    margin-top: 14px;

    &.invalid-form-profile__checkbox-1 {
      order: 2;
    }

    &.invalid-form-profile__checkbox-2 {
      order: 3;
      margin-top: 4px;
    }

    &.invalid-form-profile__checkbox-3 {
      order: 1;
    }
  }
`;

const MenuItem = styled(DefMenuItem)``;

const Form = styled(DefForm)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
  }
`;

const Title = styled(DefTitle)`
  width: 100%;
  margin-bottom: 33px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-column: 1 / -1;
  }
`;

const BottomBlock = styled.div`
  margin-top: 20px;
  flex: 0 0 100%;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-column: span 2;
    order: 4;
    margin-top: 14px;
  }
`;

const Button = styled(DefButton)`
  width: 100%;
`;

const defInitialValues: InvalidProfileFormValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  vatNumber: '',
  address: '',
  houseNumber: '',
  zip: '',
  city: '',
  country: '',
  phone: '',
  amountToInvest: 0,
  referralCode: '',
  sendMeNews: false,
  olderThan18AndTermsAccepted: false,
  notUsPerson: false,
};

const getDataByFieldName: (t: TFunction) => {
  [key in keyof Required<InvalidProfileFormValues>]: {
    Component?: typeof FormikInput | typeof FormikCheckboxWithLabel;
    type?: 'select';
    extraProps?: Omit<FormikInputProps, 'name'>;
  };
} = (t) => ({
  firstName: {
    extraProps: {
      label: t<string>('COMMON_FORMS__firstNameFieldPlaceholder'),
    },
  },
  lastName: {
    extraProps: {
      label: t<string>('COMMON_FORMS__lastNameFieldPlaceholder'),
    },
  },
  companyName: {
    extraProps: {
      label: (
        <>
          {t('COMMON_FORMS__companyNamePlaceholder')}{' '}
          <SmallInputPlaceholderText>
            {t<string>('COMMON_FORMS__companyNameDescPlaceholder')}
          </SmallInputPlaceholderText>
        </>
      ),
      fullWidth: true,
    },
  },
  vatNumber: {
    extraProps: {
      label: (
        <>
          {t('COMMON_FORMS__vatNumberPlaceholder')}{' '}
          <SmallInputPlaceholderText>
            {t<string>('COMMON_FORMS__vatNumberDescPlaceholder')}
          </SmallInputPlaceholderText>
        </>
      ),
      fullWidth: true,
    },
  },
  address: {
    extraProps: {
      label: t<string>('COMMON_FORMS__addressFieldPlaceholder'),
    },
  },
  houseNumber: {
    extraProps: {
      label: t<string>('COMMON_FORMS__houseNumberFieldPlaceholder'),
    },
  },
  zip: {
    extraProps: {
      label: t<string>('COMMON_FORMS__zipFieldPlaceholder'),
    },
  },
  city: {
    extraProps: {
      label: t<string>('COMMON_FORMS__cityFieldPlaceholder'),
    },
  },
  phone: {
    extraProps: {
      label: t<string>('COMMON_FORMS__phoneNumberFieldPlaceholder'),
    },
  },
  country: {
    Component: CountriesSelect,
    extraProps: {
      label: t<string>('COMMON_FORMS__countryFieldPlaceholder'),
    },
  },
  amountToInvest: {
    type: 'select',
    extraProps: {
      label: t<string>('INVALID_PROFILE_FORM__amountToInvestFieldPlaceholder'),
      select: true,
      InputLabelProps: { shrink: true },
      children: investOptions.map(({ label, value }) => (
        <MenuItem key={label} style={{ width: '100%' }} value={value}>
          {label}
        </MenuItem>
      )),
    },
  },
  referralCode: {
    extraProps: {
      label: t<string>('INVALID_PROFILE_FORM__referralFieldPlaceholder'),
      InputLabelProps: { shrink: true },
    },
  },
  sendMeNews: {
    Component: Checkbox,
    extraProps: {
      className: 'invalid-form-profile__checkbox-1',
      label: (
        <span>
          {t<string>('INVALID_PROFILE_FORM__infoMailFieldPlaceholder')}.
        </span>
      ),
    },
  },
  olderThan18AndTermsAccepted: {
    Component: Checkbox,
    extraProps: {
      className: 'invalid-form-profile__checkbox-2',
      label: (
        <span>
          <>
            {t('INVALID_PROFILE_FORM__ageAndTermsFieldPlaceholder')}{' '}
            <Link
              href={`${SiteData.TERMS_LINK}`}
              target={'_blank'}
              rel="noreferrer"
            >
              {t<string>('INVALID_PROFILE_FORM__termsText')}
            </Link>{' '}
            {t('INVALID_PROFILE_FORM__andText')}{' '}
            <Link
              href={`${SiteData.PRIVACY_LINK}`}
              target={'_blank'}
              rel="noreferrer"
            >
              {t<string>('INVALID_PROFILE_FORM__privacyText')}
            </Link>{' '}
            {t('INVALID_PROFILE_FORM__ageAndTermsFieldPlaceholder2')}.
          </>
        </span>
      ),
    },
  },
  notUsPerson: {
    Component: Checkbox,
    extraProps: {
      className: 'invalid-form-profile__checkbox-3',
      label: (
        <span>
          <>
            {t('INVALID_PROFILE_FORM__usFieldPlaceholder1')}{' '}
            <Link
              href={`${SiteData.NOT_US_PERSON_LINK}`}
              target={'_blank'}
              rel="noreferrer"
            >
              {t<string>('INVALID_PROFILE_FORM__usFieldPlaceholder2')}
            </Link>{' '}
            {t('INVALID_PROFILE_FORM__usFieldPlaceholder3')}
          </>
        </span>
      ),
    },
  },
});

export { InvalidProfileForm };
