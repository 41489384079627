/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { isFunction } from 'lodash-es';
import {
  Dialog as DefDialog,
  DialogContent as DefDialogContent,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { ColorName } from '../../utils/colors';
import { getColor, getDownMedia } from '../../styles';
import { Title } from '../texts';
import { ModalCloseButton } from './index';

export type ModalWrapperProps = DialogProps & {
  modalName: string;
  close: () => void;
  titleText?: ReactNode;
  titleLineColor?: ColorName;
  withCloseOption?: boolean;
  styleType?: StyleType;
  alwaysContentScrollbar?: boolean;
  noScrollBar?: boolean;
  topImg?: string;
};

const ModalWrapper: FC<ModalWrapperProps> = ({
  children,
  close,
  modalName,
  titleText,
  titleLineColor,
  withCloseOption = true,
  styleType = 'normal',
  alwaysContentScrollbar = false,
  noScrollBar = false,
  topImg,
  ...props
}) => (
  <Dialog
    aria-labelledby={`${modalName}-modal-title`}
    $withCloseButton={withCloseOption}
    $styleType={styleType}
    onClose={withCloseOption && isFunction(close) ? close : undefined}
    {...props}
  >
    {!!topImg && <TopImg src={topImg} alt={`Top ${modalName} image`} />}
    {(() => {
      if (!(withCloseOption && isFunction(close))) return null;

      return (
        <ModalCloseButton
          aria-label={'Close'}
          withTopImg={styleType === 'withTopImage'}
          onClick={close}
        />
      );
    })()}
    {titleText && (
      <DialogTitle
        id={`${modalName}-modal-title`}
        component={'h2'}
        withLine
        titleLineColor={titleLineColor}
      >
        {titleText}
      </DialogTitle>
    )}
    <DialogContent
      $noScrollBar={noScrollBar}
      $alwaysContentScrollbar={alwaysContentScrollbar}
    >
      {children}
    </DialogContent>
  </Dialog>
);

type StyleType =
  | 'normal'
  | 'wide'
  | 'specialExpectedPortfolio'
  | 'withTopImage';

// noinspection CssUnusedSymbol
const paperStyles: {
  [key in StyleType]: ReturnType<typeof css>;
} = {
  // @ts-ignore
  normal: css<{
    $withCloseButton: boolean;
  }>`
    max-width: 510px;
    padding: 50px 46px 50px;

    ${getDownMedia('sm')} {
      padding: ${({ $withCloseButton }) => ($withCloseButton ? 32 : 20)}px 26px
        36px;
    }
  `,
  // @ts-ignore
  wide: css<{
    $withCloseButton: boolean;
  }>`
    max-width: 710px;
    padding: 50px 86px 70px;

    ${getDownMedia('sm')} {
      padding: ${({ $withCloseButton }) => ($withCloseButton ? 32 : 20)}px 10px
        36px;
    }
  `,
  // @ts-ignore
  specialExpectedPortfolio: css<{
    $withCloseButton: boolean;
  }>`
    max-width: 700px;

    ${getDownMedia('sm')} {
      max-width: calc(100% - 20px);
    }

    .MuiDialogContent-root {
      padding: 32px 24px 32px;

      ${getDownMedia('sm')} {
        padding: 20px 20px 20px;
      }
    }
  `,
  withTopImage: css`
    max-width: 580px;
    padding: 0;

    .MuiDialogContent-root {
      padding: 20px 50px 50px;

      ${getDownMedia('sm')} {
        padding: 20px 20px 20px;
      }
    }
  `,
};

// noinspection CssUnusedSymbol
const Dialog = styled(DefDialog)<{
  $withCloseButton: boolean;
  $styleType: StyleType;
}>`
  .MuiDialog-paper {
    flex-grow: 1;
    ${({ $styleType }) => paperStyles[$styleType]};
    position: relative;
  }
`;

const TopImg = styled.img`
  margin-bottom: 10px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
`;

const DialogTitle = styled(Title)`
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 10px;
    margin-bottom: 6px;
  }
`;

const DialogContent = styled(DefDialogContent)<{
  $alwaysContentScrollbar: boolean;
  $noScrollBar: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-x: hidden;
  ${({ $noScrollBar }) =>
    $noScrollBar &&
    css`
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        display: none;
      }

      ::-webkit-scrollbar-thumb {
        display: none;
      }
    `};

  ${({ $alwaysContentScrollbar }) =>
    $alwaysContentScrollbar &&
    css`
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: ${getColor('beige')};
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    `};
`;

export { ModalWrapper };
