/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { IndexTablePopup } from '../../styled/portfolio';
import { Icon } from '../alert-modals/Icon';
import { Text } from '../alert-modals/Text';
import { Title } from '../alert-modals/Title';

export type RebalanceInProgressPopupProps = ComponentProps<
  typeof IndexTablePopup
>;

const RebalanceInProgressPopup = (props: RebalanceInProgressPopupProps) => {
  const { t } = useTranslation();

  return (
    <IndexTablePopup square={false} {...props}>
      <Icon type={'successBlue'} />
      <Title>
        {t('INDEX_PORTFOLIO_TABLE_REBALANCE_IN_PROGRESS_POPUP__title')}
      </Title>
      <Text>{t('INDEX_PORTFOLIO_TABLE_REBALANCE_IN_PROGRESS__desc')}</Text>
    </IndexTablePopup>
  );
};

export { RebalanceInProgressPopup };
