/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import { colors } from '../../utils/colors';

export type ContentModalWrapperProps = {
  opened: boolean;
  animation?: ReturnType<typeof useSpring>;
} & HTMLAttributes<HTMLDivElement>;

const ContentModalWrapper: FC<ContentModalWrapperProps> = ({
  children,
  opened,
  animation: customAnimation,
  ...props
}) => {
  const animation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: opened ? 1 : 0,
    },
  });

  return (
    <Wrapper style={customAnimation || animation} opened={opened} {...props}>
      {children}
    </Wrapper>
  );
};

export const Wrapper = styled(({ opened, ...props }) => (
  <animated.section {...props} />
))<{ opened: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  pointer-events: ${({ opened }) => (opened ? 'auto' : 'none')};
  overflow: scroll;
  background-color: ${colors.lightBlue};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px;
  }
`;

export { ContentModalWrapper };
