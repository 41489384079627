/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useURLParams = () => {
  const { search } = useLocation();

  return useMemo<{
    urlSearchParams: URLSearchParams;
    urlSearchParamsAsArray: Array<[string, string]>;
  }>(() => {
    try {
      const urlSearchParams = new URLSearchParams(search);
      return {
        urlSearchParams,
        urlSearchParamsAsArray: Array.from(urlSearchParams.entries()),
      };
    } catch (e) {
      return {
        urlSearchParams: new URLSearchParams(),
        urlSearchParamsAsArray: [],
      };
    }
  }, [search]);
};
