/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { externalLinkProps, SiteData } from '../../utils/consts';
import { getColor } from '../../styles';
import { useModals } from '../../providers';
import krakenImg from '../../assets/images/kraken-info-modal.jpg';
import { Button as DefButton } from '../buttons';
import { Text as DefText } from '../texts';

const KrakenInfoModal = () => {
  const { t } = useTranslation();
  const { data, dispatch } = useModals<'krakenInfoModal'>();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'kraken-info',
        styleType: 'withTopImage',
        topImg: krakenImg,
        ...(data?.initialPortfolioModalAfterClose
          ? {
              close: () => {
                dispatch({
                  type: 'setModalContent',
                  payload: {
                    // @ts-ignore
                    name: 'initialPortfolioModal',
                  },
                });
              },
            }
          : {}),
        alwaysContentScrollbar: true,
      },
    });
  }, [data, dispatch]);

  return (
    <>
      <Title variant={'h1'} id={'kraken-info-modal-title'}>
        {t('KRAKEN_INFO_MODAL__title')}
      </Title>
      <Text>{t('KRAKEN_INFO_MODAL__text1')}</Text>
      <SubTitle variant={'h2'}>{t('KRAKEN_INFO_MODAL__subTitle1')}</SubTitle>
      <Text>
        <Trans
          i18nKey={'KRAKEN_INFO_MODAL__text2'}
          components={{
            list: <ul />,
            listItem: <li />,
          }}
        />
      </Text>
      <SubTitle variant={'h2'}>{t('KRAKEN_INFO_MODAL__subTitle2')}</SubTitle>
      <Text>
        <Trans
          i18nKey={'KRAKEN_INFO_MODAL__text3'}
          components={{
            list: <ul />,
            listItem: <li />,
          }}
        />
      </Text>
      <Button href={SiteData.KRAKEN_SIGNUP} {...externalLinkProps}>
        {t('KRAKEN_INFO_MODAL__button')}
      </Button>
    </>
  );
};

export type KrakenInfoModalData =
  | {
      initialPortfolioModalAfterClose: boolean;
    }
  | undefined;

const Text = styled(DefText)`
  ul {
    padding-left: 0;
    margin: 8px 0 0 15px;
    list-style-position: outside;
  }
`;

const Title = styled(Text)`
  margin-bottom: 12px;
`;

const SubTitle = styled(Text)`
  margin-top: 18px;
`;

const Button = styled(DefButton)`
  width: auto;
  align-self: flex-start;
  margin-top: 20px;
  /*noinspection CssUnusedSymbol*/
  .MuiButton-root {
    background-color: ${getColor('purpleHeart')};

    &:hover {
      background-color: ${getColor('veniceBlue')};
    }
  }
`;

export { KrakenInfoModal };
