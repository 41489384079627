/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from './Icon';
import { Text } from './Text';
import { Title } from './Title';

const BuySellOrderFailed = () => {
  const { t } = useTranslation();
  return (
    <>
      <Icon type={'sadFace'} />
      <Title>{t('NOTIFICATION_BUY_SELL_ORDER_FAILED__title')}</Title>
      <Text>{t('NOTIFICATION_BUY_SELL_ORDER_FAILED__desc')}</Text>
    </>
  );
};

export { BuySellOrderFailed };
