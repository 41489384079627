/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useEffect, RefObject, useMemo } from 'react';
import { useIntersection } from 'react-use';
import { useSpring } from '@react-spring/web';
import { useIsMobile } from './useIsMobile';

export const useHorizontalDemoScroll = <T extends HTMLElement>(
  ref: RefObject<T>,
  incConfig?: Config
) => {
  const mobile = useIsMobile();

  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.6,
  });

  const config = useMemo(
    () => ({ ...defaultConfig, ...incConfig }),
    [incConfig]
  );

  const [{ scroll }, api] = useSpring(() => ({
    from: { scroll: 0 },
    to: async (next) => {
      await next({ scroll: config.scrollValue });
      await next({ scroll: 0 });
    },
    pause: true,
  }));

  useEffect(() => {
    if (
      config.disabled ||
      !mobile ||
      !ref.current ||
      ref.current.scrollLeft !== 0
    )
      return;
    if (!intersection || !intersection.isIntersecting) return;
    api.resume();
  }, [config, mobile, ref, intersection, api]);

  return scroll;
};

type Config = {
  disabled?: boolean;
  scrollValue?: number;
};

const defaultConfig: Required<Config> = {
  disabled: false,
  scrollValue: 150,
};
