/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled, { css } from 'styled-components';
import { TableRow as DefTableRow } from '@mui/material';
import { colors } from '../../utils/colors';
import { semiBoldText } from '../../utils/fonts';
import {
  ExpectedPortfolioTokenStatus,
  ExpectedPortfolioRowResponse,
} from '../../apollo';
import { getColor } from '../../styles';

// noinspection CssUnusedSymbol
export const TableRow = styled(DefTableRow)<{
  $status?: ExpectedPortfolioRowResponse['status'];
}>`
  border-bottom: 1px solid ${colors.lightGray};
  color: ${getColor('darkBlue')};

  ${({ $status }) => {
    switch ($status) {
      case ExpectedPortfolioTokenStatus.New:
        return css`
          color: ${getColor('jade')};
          ${semiBoldText};
        `;
      case ExpectedPortfolioTokenStatus.Removed:
        return css`
          color: ${getColor('flamingo')};
          ${semiBoldText};
        `;
      default:
        return null;
    }
  }}
`;
