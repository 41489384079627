/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty, isNil, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { portfolioDataPollInterval } from '../../utils/portfolio';
import { sendSentryError } from '../../utils/sentry';
import {
  useIsTablet,
  useWindowTabIsActive,
  useUserNotifications,
  useUrlExpectedPortfolioModal,
} from '../../hooks';
import { LastRebalanceReasonType, useDashboardDataQuery } from '../../apollo';
import { getUpMedia, getColor } from '../../styles';
import { ExchangeWizard, useExchange, useAlert } from '../../providers';
import { Spinner } from '../../styled';
import { PortfolioCurrencySwitcher } from '../../components/PortfolioCurrencySwitcher';
import { ExchangeWizardStepper } from '../../components/content-modals';
import { ExchangeKeysForm } from '../../components/forms';
import {
  ExchangesMenu as DefExchangesMenu,
  IndexPortfolioPie,
  IndexPortfolioTable,
  IndexPortfolioGraph,
  IndexPortfolioDeposit,
  IndexPortfolioWithdraw,
  PortfolioIndexDescription,
  PrivatePortfolioTable,
  PrivatePortfolioTablePlaceholder,
} from '../../components/portfolio';
import { AppPageLayout } from '../../layouts';
import { BlockTitle } from '../../styled/portfolio';

const DashboardPage = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  const tablet = useIsTablet();

  const { exchange, exchangeWizardActive } = useExchange();

  const tabIsActive = useWindowTabIsActive();

  const { loading, data, error, refetch, startPolling, stopPolling } =
    useDashboardDataQuery({
      fetchPolicy: 'no-cache',
      skip: !exchange?.exchangeID,
      pollInterval: portfolioDataPollInterval,
      variables: {
        exchangeId: toNumber(exchange?.exchangeID),
      },
      onError: (e) => {
        setAlert({
          type: 'error',
          message: t('DASHBOARD_PAGE__fetchDataError'),
        });
        sendSentryError(e);
      },
    });

  useUrlExpectedPortfolioModal();
  useUserNotifications();

  const hidePrivatePortfolio = useMemo(
    () =>
      exchange?.sentToRebalancing &&
      [
        LastRebalanceReasonType.Failed,
        LastRebalanceReasonType.FailedReinit,
        LastRebalanceReasonType.FailedTransactions,
      ].some((reason) => exchange?.lastRebalanceReasonResponse === reason),
    [exchange]
  );

  useUpdateEffect(() => {
    refetch();
  }, [exchangeWizardActive, refetch]);

  useUpdateEffect(() => {
    if (!exchange?.exchangeID) return;
    if (tabIsActive) {
      refetch();
      startPolling(portfolioDataPollInterval);
    } else {
      stopPolling();
    }
  }, [exchange?.exchangeID, tabIsActive, refetch, startPolling, stopPolling]);

  if (exchangeWizardActive) {
    return (
      <ExchangeWizard>
        <Wrapper>
          {tablet && <ExchangesMenu />}
          <ExchangeWizardStepper withExchangeTabs={tablet} />
        </Wrapper>
      </ExchangeWizard>
    );
  }

  if (loading && isNil(data)) {
    return (
      <Wrapper>
        <Spinner
          style={{
            gridColumn: '1 / -1',
            margin: 'auto',
          }}
        />
      </Wrapper>
    );
  }

  if (error || isNil(data)) {
    return <Wrapper>Error</Wrapper>;
  }

  return (
    <Wrapper>
      {tablet && <ExchangesMenu />}
      <Header>
        <HeaderTitle>{t('DASHBOARD_PAGE__indexPortfolioTitle')}</HeaderTitle>
        <PortfolioCurrencySwitcher />
      </Header>
      <DesktopColumn>
        <IndexGraphSection data={data.getPortfolioGraphData} />
        <IndexTableSection>
          <IndexPortfolioTable portfolio={data.getIndexPortfolio} />
        </IndexTableSection>
        {!hidePrivatePortfolio && (
          <>
            <PrivateTableSectionTitle>
              {t('DASHBOARD_PAGE__privatePortfolioTitle')}
            </PrivateTableSectionTitle>
            <PrivateTableSection>
              {isEmpty(data.getPrivatePortfolio) ? (
                <PrivatePortfolioTablePlaceholder />
              ) : (
                <PrivatePortfolioTable portfolio={data.getPrivatePortfolio} />
              )}
            </PrivateTableSection>
          </>
        )}
      </DesktopColumn>
      <DesktopColumn>
        <IndexPieSection
          portfolio={data.getIndexPortfolio}
          portfolioTotals={data.getPortfolioTotals}
        />
        <DepositSection portfolio={data.getPrivatePortfolio} />
        <WithdrawSection />
        <APIKeysSection>
          <ExchangeKeysForm />
        </APIKeysSection>
        <IndexDescSection />
      </DesktopColumn>
    </Wrapper>
  );
};

const Wrapper = styled(AppPageLayout)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  padding-top: 80px;

  ${getUpMedia('sm')} {
    grid-template-columns: 1fr 1fr;
  }

  ${getUpMedia('md')} {
    padding-top: 30px;
  }

  ${getUpMedia('lg')} {
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 34px;
  }
`;

const ExchangesMenu = styled(DefExchangesMenu)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 20px 18px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${getColor('blue')};
  text-align: center;
`;

const Header = styled.header`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitle = styled(BlockTitle).attrs({
  component: 'h2',
})`
  margin-bottom: 0;
`;

const DesktopColumn = styled.div`
  display: contents;
  ${getUpMedia('lg')} {
    display: flex;
    flex-direction: column;
    align-self: start;
    gap: 24px;
    overflow-x: auto;
  }
`;

const sectionStyles = css`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 40px;
  border-radius: 18px;
  background-color: #fff;
  overflow-x: auto;

  @media (min-width: 1400px) {
    padding: 30px 36px 40px;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 20px 20px 30px;
  }
`;

const Section1 = styled.section`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 40px;
  border-radius: 18px;
  background-color: #fff;
  overflow-x: auto;

  @media (min-width: 1400px) {
    padding: 30px 36px 40px;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 20px 20px 30px;
  }
`;

const IndexPieSection = styled(IndexPortfolioPie)`
  ${sectionStyles};
`;

const IndexGraphSection = styled(IndexPortfolioGraph)`
  ${sectionStyles};
  order: 1;
  ${getUpMedia('sm')} {
    grid-column: 1 / -1;
    order: 2;
  }
`;

const IndexTableSection = styled.section`
  ${sectionStyles};
  order: 1;
  ${getUpMedia('sm')} {
    order: 2;
    grid-column: 1 / -1;
  }
`;

const PrivateTableSectionTitle = styled(HeaderTitle)`
  order: 1;
  margin-top: 16px;
  ${getUpMedia('sm')} {
    order: 2;
    grid-column: 1 / -1;
  }
`;

const PrivateTableSection = styled.section`
  ${sectionStyles};
  order: 1;
  ${getUpMedia('sm')} {
    order: 2;
    grid-column: 1 / -1;
  }
`;

const DepositSection = styled(IndexPortfolioDeposit)`
  ${sectionStyles};
  order: 2;
  ${getUpMedia('sm')} {
    order: 1;
  }
`;

const WithdrawSection = styled(IndexPortfolioWithdraw)`
  ${sectionStyles};
  order: 2;
  ${getUpMedia('sm')} {
    grid-column: 1 / -1;
  }
`;

const APIKeysSection = styled(Section1)`
  order: 2;
  ${getUpMedia('sm')} {
    grid-column: 1 / -1;
  }
`;

const IndexDescSection = styled(PortfolioIndexDescription)`
  ${sectionStyles};
  order: 2;
  ${getUpMedia('sm')} {
    grid-column: 1 / -1;
  }
`;

export { DashboardPage };
