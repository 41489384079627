/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { serverResponseErrorsCodes } from '../utils/consts';
import { sendSentryError } from '../utils/sentry';
import { isServerErrorOfType } from '../utils/server-helpers';
import {
  usePortfolioDepositMutation,
  PortfolioDepositMutationOptions,
} from '../apollo';
import { useAlert } from '../providers';

export const usePortfolioDeposit = (
  options: PortfolioDepositMutationOptions
) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  return usePortfolioDepositMutation({
    onError: (error) => {
      sendSentryError(error);
      setAlert({
        type: 'error',
        message: t(getErrorMessage(error)),
      });
    },
    ...options,
  });
};

const getErrorMessage = (error: ApolloError) => {
  switch (true) {
    case isServerErrorOfType({
      error,
      type: serverResponseErrorsCodes.DEPOSIT_NO_SUCH_CURRENCY_IN_PRIVATE_PORTFOLIO,
    }): {
      return 'DEPOSIT_DESC__noSuchCurrencyErrorText';
    }
    default:
      return 'DEPOSIT_DESC__errorText';
  }
};
