/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import beleggersacademy from '../assets/images/ref-logos/beleggersacademy.png';
import { useURLParams } from './useURLParams';

export const useRegisterReferral = () => {
  const { urlSearchParamsAsArray } = useURLParams();
  const company = urlSearchParamsAsArray.find(
    ([name]) => name === refParam
  )?.[1];
  return company
    ? {
        company,
        logo: logos[company],
      }
    : null;
};

const refParam = 'utm_source';

const logos: Record<string, string> = {
  beleggersacademy,
};
